import React, { Component, createRef } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Cookies from "js-cookie";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSave,
  faDownload,
  faPen,
  faArrowLeft,
  faAngleLeft,
} from "@fortawesome/free-solid-svg-icons";
import { apiUrl } from "../../config";

import f1 from "../../images/pdf/f1.png";
import f2 from "../../images/pdf/f2.png";
import f3 from "../../images/pdf/f3.png";
import ctg4 from "../../images/pdf/ctg4.png";
import f4 from "../../images/pdf/f4.png";
import f5 from "../../images/pdf/f5.png";
import f6 from "../../images/pdf/f6.png";
import f7 from "../../images/pdf/f7.png";
import f8 from "../../images/pdf/f8.png";
import f10 from "../../images/pdf/f10.png";
import f11 from "../../images/pdf/f11.png";
import f12 from "../../images/pdf/f11.png";
import g1 from "../../images/pdf/g1.png";
import g2 from "../../images/pdf/g2.png";
import g3 from "../../images/pdf/g3.png";
import g4 from "../../images/pdf/g4.png";
import g5 from "../../images/pdf/g5.png";
import g6 from "../../images/pdf/g6.png";
import g7 from "../../images/pdf/g7.png";
import g8 from "../../images/pdf/g8.png";
import g9 from "../../images/pdf/g9.png";
import h1 from "../../images/pdf/h1.png";
import h2 from "../../images/pdf/h2.png";
import h3 from "../../images/pdf/h3.png";
import h4 from "../../images/pdf/h4.png";
import h5 from "../../images/pdf/h5.png";
import l2 from "../../images/pdf/l2.png";
import dgm from "../../images/pdf/dgm.png";
import triag from "../../images/triag.png";

import TreeNode from "../../Comps/Tree";
import RiskAppreciationTable from "../../Comps/RiskAppreciationTable";
import ImageView from "../../Comps/ImageView";
import TextView from "../../Comps/TextView";
import TabelaRisco from "../../Comps/TabelaRisco";
import GraficoPizza from "../../Comps/GraficoPizza";
import Apc from "../../Comps/Apc";
import Adcs from "../../Comps/Adcs";
var conta = 0;
class AdrView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      Loader: true,
      data: [],
      form: [],
      letra: "",
      callCount: 0,
      tabela: [
        ["B", "1", "2", "3", "4"],
        ["•", "○", "●", "●", "●"],
        ["•", "•", "○", "●", "●"],
        ["", "•", "•", "○", "●"],
        ["", "•", "•", "○", "●"],
        ["", "", "", "•", "○"],
      ],
      selectedItems: [""],
      ctg: "",
      AtMlhs: [],
      DpMlhs: [],
      diagram: [],
      showCount: 1,
      conteudoexedente: [],
      apc2: [],
    };
    this.i1 = React.createRef();
    this.i2 = React.createRef();
    this.i3 = React.createRef();
    this.i4 = React.createRef();
    this.i5 = React.createRef();
    this.i6 = React.createRef();
    this.i7 = React.createRef();

    this.containerRef = React.createRef();
  }

  NumeroPorExtenso(num) {
    const unidades = [
      "zero",
      "uma",
      "dois",
      "três",
      "quatro",
      "cinco",
      "seis",
      "sete",
      "oito",
      "nove",
    ];

    const dezenas = [
      "",
      "",
      "vinte",
      "trinta",
      "quarenta",
      "cinquenta",
      "sessenta",
      "setenta",
      "oitenta",
      "noventa",
    ];

    const teens = [
      "dez",
      "onze",
      "doze",
      "treze",
      "quatorze",
      "quinze",
      "dezesseis",
      "dezessete",
      "dezoito",
      "dezenove",
    ];

    if (num < 10) return unidades[num];
    if (num < 20) return teens[num - 10];
    if (num < 100) {
      const dezena = Math.floor(num / 10);
      const unidade = num % 10;
      return unidade === 0
        ? dezenas[dezena]
        : `${dezenas[dezena]} e ${unidades[unidade]}`;
    }

    return "Número fora do intervalo suportado";
  }

  show = () => {
    conta += 1;
    return conta;
  };

  formatDate(inputDate) {
    // Cria um objeto Date a partir da string de entrada
    let date = new Date(inputDate);

    // Extrai o dia, o mês e o ano
    let day = String(date.getDate()).padStart(2, "0"); // Garante que o dia tenha dois dígitos
    let month = String(date.getMonth() + 1).padStart(2, "0"); // getMonth() retorna o mês de 0 a 11
    let year = date.getFullYear();

    // Retorna a data formatada como "dd/mm/yyyy"
    return `${day}/${month}/${year}`;
  }

  componentDidMount() {
    document.title = "Editar norma";
    fetch(
      `${apiUrl}/gerar-documento/${window.location.pathname.split("/")[2]}`,
      {
        method: "GET",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + Cookies.get("auth_token"),
        },
      }
    ).then((response) => {
      response.json().then((data) => {
        var ap = data.apc;

        var resulta = [];

        for (let i = 1; i <= 7; i++) {
          let code = `apc-${i}`;
          let item = ap.find((item) => item.code === code);

          if (item !== undefined) {
            resulta.push(item);
          } else {
            resulta.push({
              code,
              itens: [],
            });
          }
        }

        var ap2 = data.apc;
        var resulta2 = [];

        for (let i = 1; i <= 7; i++) {
          let code = `apc-${i}`;
          let item = ap2.find((item) => item.code === code);

          if (item !== undefined) {
            resulta2.push(item);
          } else {
            resulta2.push({
              code,
              itens: [],
            });
          }
        }

        this.setState({
          Loader: false,
          data,
          TextView: data.TextView,
          FormHrn: data.FormHrn,
          ImageView: data.ImageView,
          groupi: data.groupi,
          group_2: data.group_2,
          apc: data.apc,
          apc2: data.apc2,
          GroupList: data.GroupList,
          resulta,
          diagram: data.diagram,
        });

        var list = data.diagram.join();

        var letra = undefined;
        switch (list) {
          case "s1-1,f1-2,p1-3":
            letra = "a";
            this.setState({ selectedItems: ["0-1"], ctg: "1" });
            break;
          case "s1-1,f1-2,p2-4":
            this.setState({ selectedItems: ["0-1"], ctg: "1" });
            letra = "b";
            break;
          case "s1-1,f2-5,p1-6":
            letra = "b";
            this.setState({ selectedItems: ["0-1"], ctg: "1" });
            break;
          case "s1-1,f2-5,p2-7":
            letra = "c";
            this.setState({ selectedItems: ["0-1"], ctg: "1" });
            break;
          case "s2-8,f1-9,p1-10":
            letra = "c";
            this.setState({ selectedItems: ["1-2"], ctg: "2" });
            break;
          case "s2-8,f1-9,p2-11":
            this.setState({ selectedItems: ["2-3"], ctg: "3" });
            letra = "d";
            break;
          case "s2-8,f2-12,p1-13":
            letra = "d";
            this.setState({ selectedItems: ["3-3"], ctg: "3" });
            break;
          case "s2-8,f2-12,p2-14":
            letra = "e";
            this.setState({ selectedItems: ["4-4"], ctg: "4" });
            break;
          default:
            letra = "Indefinido!";
        }

        this.setState({ letra });
      });
    });

    this.verificarAltura();
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.verificarAltura);
  }

  componentDidUpdate(prevProps) {
    // Verifica a altura se o conteúdo mudar
    if (prevProps.conteudo !== this.props.conteudo) {
      this.verificarAltura();
    }
  }

  verificarAltura = () => {
    if (this.containerRef.current) {
      const altura = this.containerRef.current.offsetHeight;
      console.log("aaaaaa");
      this.setState({ isMaiorQue840px: altura > 840 });
    }
  };

  handleItemClick = (item) => {
    this.setState({ selectedItem: item });
  };

  TextView(code) {
    var r = this.state.TextView.find((item) => item.code === code);
    return <>{r != undefined && r.text}</>;
  }

  LastPage = (maxPg) => {
    var data = [
      <div className="dados">
        <div style={{ marginLeft: 20, marginBottom: 10 }}>
          <strong>13- VISÃO GERAL DE CONFORMIDADE</strong>
        </div>
        <div style={{ marginBottom: 10 }}>
          <strong>Antes das melhorias</strong>
        </div>

        <TabelaRisco data={this.state.AtMlhs} />

        <div className="pizza mt-5">
          <GraficoPizza data={this.state.AtMlhs} />
        </div>

        <div className="mt-3 mb-3">
          <strong>Após melhorias</strong>
        </div>

        <TabelaRisco data={this.state.DpMlhs} />
      </div>,
      <div className="dados">
        <div className="pizza mt-5">
          <GraficoPizza data={this.state.DpMlhs} />
        </div>
        <br />
        <div style={{ display: "table", margin: "auto" }}>
          <img src={h5} />
        </div>
      </div>,
      <div className="dados">
        <div style={{ marginBottom: 10 }}>
          <strong>14 - CONSIDERAÇÕES FINAIS</strong>
        </div>

        <div>
          <p>
            A máquina denominada{" "}
            <strong>{this.ShowText("dffhdf6h1str")}</strong> com os sistemas de
            segurança, arquitetura elétrica e interface de segurança deve
            atender no mínimo <strong>categoria {this.state.ctg}</strong> de
            segurança, levando em consideração as ações propostas como sugestão
            de melhoria referente a cada item avaliado, segundo NR-12, NBR
            12.153: 2015, ISO TR 24119: 2015, ISO 13849-1: 2019 e NBR ISO 12100:
            2013.
          </p>
        </div>

        <div>
          <p>
            Vale ressaltar que, as imagens contidas neste documento são de uso
            orientativo. A adequação deve ser executada por profissionais
            habilitados e capacitados para este fim.
          </p>
        </div>

        <div>
          <p>
            Deve ser elaborado projeto elétrico / mecânico dos sistemas de
            segurança com base nos itens aplicáveis da NR-12 levando como base a
            apreciação de riscos.
          </p>
        </div>

        <div>
          <p>
            Proteções fixas, móveis e dispositivos opto eletrônicos devem
            atender as distancias de segurança do ANEXO 1 da NR-12 e EN ISO
            13857: 2021.
          </p>
        </div>
      </div>,
      <div className="dados">
        <div style={{ marginBottom: 10 }}>
          <strong>15 - CORPO TÉCNICO RESPONSÁVEL</strong>
        </div>

        <div>
          <p>
            Este documento é composto por{" "}
            <strong>{this.NumeroPorExtenso(maxPg)}</strong> páginas numeradas e
            assinado na última página pelo corpo técnico responsável pela
            avaliação e elaboração da “A.R Apreciação de Riscos”{" "}
          </p>
          <div>
            <label>Data:</label>
            <TextView type="view" code="d6554fgj" data={this.state.TextView} />
          </div>
        </div>

        <div className="aln-f">
          <div className="sub">
            <hr />
            {this.state.data.tecs.map((item, index) => (
              <div style={{ marginBottom: 10 }}>
                <strong>{item.nome}</strong> - {item.func}
              </div>
            ))}
            <strong>{this.state.data.executante.razao_social}</strong>
          </div>
        </div>
      </div>,
      <>
        <div className="al-c">
          <strong>Anexos:</strong>
        </div>
        <div className="al-c">
          <strong>ART de Segurança:</strong>
        </div>
      </>,
    ];
    return { data, maxPg };
  };

  ViewImage(code) {
    var r = this.state.ImageView.find((item) => item.code === code);

    return r != undefined && r.img;
  }

  ShowText(code) {
    var r = this.state.TextView.find((item) => item.code === code);
    return <>{r != undefined ? r.text.toUpperCase() : ""}</>;
  }

  ItemTeste() {
    return [0, 1, 2];
  }

  render() {
    if (this.state.Loader) {
      return <>Carregando..</>;
    }
    const { selectedItems } = this.state;

    var info = this.state.data.body;

    const apc2Item1 = this.state.apc2.find((item) => item.code === "12.1") || {
      code: "12.1",
      itens: [{ text: "" }],
    };
    const apc2Item2 = this.state.apc2.find((item) => item.code === "12.2") || {
      code: "12.2",
      itens: [{ text: "" }],
    };

    const apc2Item3 = this.state.apc2.find((item) => item.code === "12.3") || {
      code: "12.3",
      itens: [{}],
    };
    const apc2Item4 = this.state.apc2.find((item) => item.code === "12.4") || {
      code: "12.4",
      itens: [{}],
    };
    const apc2Item5 = this.state.apc2.find((item) => item.code === "12.5") || {
      code: "12.5",
      itens: [{}],
    };
    const apc2Item6 = this.state.apc2.find((item) => item.code === "12.6") || {
      code: "12.6",
      itens: [{}],
    };
    const apc2Item7 = this.state.apc2.find((item) => item.code === "12.7") || {
      code: "12.7",
      itens: [{}],
    };

    const stc1 =
      apc2Item1.itens.length > 1
        ? apc2Item1.itens.slice(1).map((item, index, array) => {
            const isLastItem = index === array.length - 1;
            return (
              <div className="dados">
                {item.text}
                <div className="d-flex justify-content-center mt-2">
                  {item.img1 != null && (
                    <img
                      src={`${apiUrl}/img/${item.img1}`}
                      style={{
                        width: 250,
                        height: 250,
                        marginRight: 10,
                        border: "solid 1px",
                      }}
                    />
                  )}
                  {item.img2 != null && (
                    <img
                      src={`${apiUrl}/img/${item.img2}`}
                      style={{ width: 250, height: 250, border: "solid 1px" }}
                    />
                  )}
                </div>
                <div className="d-flex justify-content-center mt-2">
                  {item.img3 != null && (
                    <img
                      src={`${apiUrl}/img/${item.img3}`}
                      style={{
                        width: 250,
                        height: 250,
                        marginRight: 10,
                        border: "solid 1px",
                      }}
                    />
                  )}
                  {item.img4 != null && (
                    <img
                      src={`${apiUrl}/img/${item.img4}`}
                      style={{ width: 250, height: 250, border: "solid 1px" }}
                    />
                  )}
                </div>
                {isLastItem && (
                  <>
                    <RiskAppreciationTable
                      code="hrn-1"
                      data={this.state.FormHrn}
                      resp={(value) => {
                        this.setState((prevState) => ({
                          AtMlhs: [...prevState.AtMlhs, value],
                        }));
                      }}
                      title="APRECIAÇÃO DE RISCO ANTES DAS AÇÕES DE MELHORIAS"
                    />
                  </>
                )}
              </div>
            );
          })
        : [];

    const stc2 =
      apc2Item2.itens.length > 1
        ? apc2Item2.itens.slice(1).map((item, index, array) => {
            const isLastItem = index === array.length - 1;
            return (
              <>
                {item.text}
                <div className="d-flex justify-content-center mt-2">
                  {item.img1 != null && (
                    <img
                      src={`${apiUrl}/img/${item.img1}`}
                      style={{
                        width: 250,
                        height: 250,
                        marginRight: 10,
                        border: "solid 1px",
                      }}
                    />
                  )}
                  {item.img2 != null && (
                    <img
                      src={`${apiUrl}/img/${item.img2}`}
                      style={{ width: 250, height: 250, border: "solid 1px" }}
                    />
                  )}
                </div>
                <div className="d-flex justify-content-center mt-2">
                  {item.img3 != null && (
                    <img
                      src={`${apiUrl}/img/${item.img3}`}
                      style={{
                        width: 250,
                        height: 250,
                        marginRight: 10,
                        border: "solid 1px",
                      }}
                    />
                  )}
                  {item.img4 != null && (
                    <img
                      src={`${apiUrl}/img/${item.img4}`}
                      style={{ width: 250, height: 250, border: "solid 1px" }}
                    />
                  )}
                  {isLastItem && (
                    <>
                      {" "}
                      <RiskAppreciationTable
                        code="hrn-3"
                        data={this.state.FormHrn}
                        resp={(value) => {
                          this.setState((prevState) => ({
                            AtMlhs: [...prevState.AtMlhs, value],
                          }));
                        }}
                        title="APRECIAÇÃO DE RISCO ANTES DAS AÇÕES DAS MELHORIAS "
                      />
                    </>
                  )}
                </div>
              </>
            );
          })
        : [];

    const stc3 =
      apc2Item3.itens.length > 1
        ? apc2Item3.itens.slice(1).map((item, index, array) => {
            const isLastItem = index === array.length - 1;
            return (
              <>
                {item.text}

                <div className="d-flex justify-content-center mt-2">
                  {item.img1 != null && (
                    <img
                      src={`${apiUrl}/img/${item.img1}`}
                      style={{
                        width: 250,
                        height: 250,
                        marginRight: 10,
                        border: "solid 1px",
                      }}
                    />
                  )}
                  {item.img2 != null && (
                    <img
                      src={`${apiUrl}/img/${item.img2}`}
                      style={{ width: 250, height: 250, border: "solid 1px" }}
                    />
                  )}
                </div>
                <div className="d-flex justify-content-center mt-2">
                  {item.img3 != null && (
                    <img
                      src={`${apiUrl}/img/${item.img3}`}
                      style={{
                        width: 250,
                        height: 250,
                        marginRight: 10,
                        border: "solid 1px",
                      }}
                    />
                  )}
                  {item.img4 != null && (
                    <img
                      src={`${apiUrl}/img/${item.img4}`}
                      style={{ width: 250, height: 250, border: "solid 1px" }}
                    />
                  )}
                </div>

                {isLastItem && (
                  <>
                    <RiskAppreciationTable
                      code="hrn-5"
                      data={this.state.FormHrn}
                      resp={(value) => {
                        this.setState((prevState) => ({
                          AtMlhs: [...prevState.AtMlhs, value],
                        }));
                      }}
                      title="APRECIAÇÃO DE RISCO ANTES DAS AÇÕES DE MELHORIAS "
                    />
                  </>
                )}
              </>
            );
          })
        : [];

    const stc4 =
      apc2Item4.itens.length > 1
        ? apc2Item4.itens.slice(1).map((item, index, array) => {
            const isLastItem = index === array.length - 1;
            return (
              <>
                {item.text}

                <div className="d-flex justify-content-center mt-2 mb-2">
                  {item.img1 != null && (
                    <img
                      src={`${apiUrl}/img/${item.img1}`}
                      style={{
                        width: 250,
                        height: 250,
                        marginRight: 10,
                        border: "solid 1px",
                      }}
                    />
                  )}
                  {item.img2 != null && (
                    <img
                      src={`${apiUrl}/img/${item.img2}`}
                      style={{ width: 250, height: 250, border: "solid 1px" }}
                    />
                  )}
                </div>
                {item.text2}
                <div className="d-flex justify-content-center mt-2">
                  {item.img3 != null && (
                    <img
                      src={`${apiUrl}/img/${item.img3}`}
                      style={{
                        width: 250,
                        height: 250,
                        marginRight: 10,
                        border: "solid 1px",
                      }}
                    />
                  )}
                  {item.img4 != null && (
                    <img
                      src={`${apiUrl}/img/${item.img4}`}
                      style={{ width: 250, height: 250, border: "solid 1px" }}
                    />
                  )}
                </div>
                {isLastItem && (
                  <>
                    <RiskAppreciationTable
                      code="hrn-7"
                      data={this.state.FormHrn}
                      resp={(value) => {
                        this.setState((prevState) => ({
                          AtMlhs: [...prevState.AtMlhs, value],
                        }));
                      }}
                      title="APRECIAÇÃO DE RISCO ANTES DAS AÇÕES DE MELHORIAS "
                    />
                  </>
                )}
              </>
            );
          })
        : [];

    const stc5 =
      apc2Item5.itens.length > 1
        ? apc2Item5.itens.slice(1).map((item, index, array) => {
            const isLastItem = index === array.length - 1;
            return (
              <div className="dados">
                {item.text}

                <div className="d-flex justify-content-center mt-2 mb-2">
                  {item.img1 != null && (
                    <img
                      src={`${apiUrl}/img/${item.img1}`}
                      style={{
                        width: 250,
                        height: 250,
                        marginRight: 10,
                        border: "solid 1px",
                      }}
                    />
                  )}
                  {item.img2 != null && (
                    <img
                      src={`${apiUrl}/img/${item.img2}`}
                      style={{ width: 250, height: 250, border: "solid 1px" }}
                    />
                  )}
                </div>
                {item.text2}
                <div className="d-flex justify-content-center mt-2">
                  {item.img3 != null && (
                    <img
                      src={`${apiUrl}/img/${item.img3}`}
                      style={{
                        width: 250,
                        height: 250,
                        marginRight: 10,
                        border: "solid 1px",
                      }}
                    />
                  )}
                  {item.img4 != null && (
                    <img
                      src={`${apiUrl}/img/${item.img4}`}
                      style={{ width: 250, height: 250, border: "solid 1px" }}
                    />
                  )}
                </div>

                {isLastItem && (
                  <RiskAppreciationTable
                    code="hrn-9"
                    data={this.state.FormHrn}
                    resp={(value) => {
                      this.setState((prevState) => ({
                        AtMlhs: [...prevState.AtMlhs, value],
                      }));
                    }}
                    title="APRECIAÇÃO DE RISCO ANTES DAS AÇÕES DE MELHORIAS "
                  />
                )}
              </div>
            );
          })
        : [];

    const stc6 =
      apc2Item6.itens.length > 1
        ? apc2Item6.itens.slice(1).map((item, index, array) => {
            const isLastItem = index === array.length - 1;
            return (
              <>
                {item.text}

                <div className="d-flex justify-content-center mt-2 mb-2">
                  {item.img1 != null && (
                    <img
                      src={`${apiUrl}/img/${item.img1}`}
                      style={{
                        width: 250,
                        height: 250,
                        marginRight: 10,
                        border: "solid 1px",
                      }}
                    />
                  )}
                  {item.img2 != null && (
                    <img
                      src={`${apiUrl}/img/${item.img2}`}
                      style={{ width: 250, height: 250, border: "solid 1px" }}
                    />
                  )}
                </div>
                {item.text2}
                <div className="d-flex justify-content-center mt-2">
                  {item.img3 != null && (
                    <img
                      src={`${apiUrl}/img/${item.img3}`}
                      style={{
                        width: 250,
                        height: 250,
                        marginRight: 10,
                        border: "solid 1px",
                      }}
                    />
                  )}
                  {item.img4 != null && (
                    <img
                      src={`${apiUrl}/img/${item.img4}`}
                      style={{ width: 250, height: 250, border: "solid 1px" }}
                    />
                  )}
                </div>

                {isLastItem && (
                  <>
                    <RiskAppreciationTable
                      code="hrn-11"
                      data={this.state.FormHrn}
                      resp={(value) => {
                        this.setState((prevState) => ({
                          AtMlhs: [...prevState.AtMlhs, value],
                        }));
                      }}
                      title="APRECIAÇÃO DE RISCO ANTES DAS AÇÕES DE MELHORIAS "
                    />
                  </>
                )}
              </>
            );
          })
        : [];

    const stc7 =
      apc2Item7.itens.length > 1
        ? apc2Item7.itens.slice(1).map((item, index, array) => {
            const isLastItem = index === array.length - 1;
            return (
              <>
                {item.text}

                <div className="d-flex justify-content-center mt-2">
                  {item.img1 != null && (
                    <img
                      src={`${apiUrl}/img/${item.img1}`}
                      style={{
                        width: 250,
                        height: 250,
                        marginRight: 10,
                        border: "solid 1px",
                      }}
                    />
                  )}
                  {item.img2 != null && (
                    <img
                      src={`${apiUrl}/img/${item.img2}`}
                      style={{ width: 250, height: 250, border: "solid 1px" }}
                    />
                  )}
                </div>
                <div className="d-flex justify-content-center mt-2">
                  {item.img3 != null && (
                    <img
                      src={`${apiUrl}/img/${item.img3}`}
                      style={{
                        width: 250,
                        height: 250,
                        marginRight: 10,
                        border: "solid 1px",
                      }}
                    />
                  )}
                  {item.img4 != null && (
                    <img
                      src={`${apiUrl}/img/${item.img4}`}
                      style={{ width: 250, height: 250, border: "solid 1px" }}
                    />
                  )}
                </div>

                {isLastItem && (
                  <>
                    <RiskAppreciationTable
                      code="hrn-13"
                      data={this.state.FormHrn}
                      resp={(value) => {
                        this.setState((prevState) => ({
                          AtMlhs: [...prevState.AtMlhs, value],
                        }));
                      }}
                      title="APRECIAÇÃO DE RISCO ANTES DAS AÇÕES DE MELHORIAS "
                    />
                  </>
                )}
              </>
            );
          })
        : [];

    var itens = [
      <>
        <div className="smr">
          <span>Sumário</span>
          <div className="sub"></div>
          <div className="fn"></div>
        </div>
        <ul className="topic-list">
          {[
            "1- INTRODUÇÃO 3",
            "2- NORMAS REFERENCIADAS 4",
            "3- CARACTERIZAÇÃO DA EMPRESA SOLICITANTE 5",
            "4- CARACTERIZAÇÃO DA EMPRESA EXECUTANTE 5",
            "5- RESPONSÁVEIS TÉCNICOS 6",
            "6- ESCOPO 7",
            "7- MÉTODO DE AVALIAÇÃO PARA ESTIMATIVA DE RISCO CONFORME METODOLOGIA HAZARD RATING NUMBER (HRN) 8",
            "8- DETERMINAÇÃO DA CATEGORIA 11",
            "9- DOCUMENTAÇÃO DO EQUIPAMENTO: 21",
            "10- VISÃO GERAL DA MÁQUINA 22",
            "11- DEFINIÇÃO DA CATEGORIA DE SEGURANÇA 26",
            "12- AVALIAÇÃO – APRECIAÇÃO DE RISCOS 28",
            "12.1 – Arranjo físico das instalações – Geral 28",
            "12.2 Instalações e dispositivos elétricos – Geral 28",
            "12.3 Dispositivo de partida, acionamento e parada – Geral 37",
            "12.4 Sistemas de Segurança 38",
            "12.5 Dispositivo de parada de emergência – Geral 51",
            "12.6 Manutenção, inspeção, preparação, ajuste, reparo e limpeza. 51",
            "12.7 Sinalização, manuais, procedimentos e capacitação 53",
            "13- VISÃO GERAL DE CONFORMIDADE 55",
            "14- CONSIDERAÇÕES FINAIS 56",
            "15- CORPO TÉCNICO RESPONSÁVEL 57",
          ].map((topic, index) => {
            const [leftPart, rightPart] = topic.split(/\s(\d+)$/);
            return (
              <li key={index} className="topic-item">
                <span className="topic-left">{leftPart}</span>
                <span className="dots"></span>
                <span className="topic-right">{rightPart}</span>
              </li>
            );
          })}
        </ul>
        <br />
      </>,
      <>
        <div className="txt-p3">
          <span>1- INTRODUÇÃO</span>
          <div>
            Tendo em vista a adequação de segurança para máquinas e equipamentos
            conforme NR-12 port. 916 de 30 de julho de 2019 o presente documento
            denominado “A.R”. – Apreciação de Riscos tem como objetivo realizar
            a análise técnica do equipamento:{" "}
            <span className="ttl">{this.ShowText("dffhdf6h1str")}</span> segundo
            normas técnicas oficiais vigentes.
          </div>
        </div>
      </>,
      <>
        <div className="ttl">2- NORMAS REFERENCIADAS</div>
        <div class="area-normas">
          {this.state.data.normas.length > 0 ? (
            <>
              {this.state.data.normas.map((item) => (
                <div className="item">
                  <strong>{item.titulo}</strong> - <label>{item.cont}</label>
                </div>
              ))}
            </>
          ) : (
            <>Não há dados!</>
          )}
        </div>
      </>,
      <>
        <div className="apst">
          <strong className="ttl">
            3- CARACTERIZAÇÃO DA EMPRESA SOLICITANTE
          </strong>
          <div className="img-slc">
            <img src={`${apiUrl}/img/${this.state.data.contratante.logo}`} />
          </div>
          <div className="dados">
            <div className="item">
              <strong>Razão social </strong>
              <label>{this.state.data.contratante.razao_social}</label>
            </div>

            <div className="item">
              <strong>Cnpj: </strong>
              <label>{this.state.data.contratante.cnpj}</label>
            </div>

            <div className="item">
              <strong>Endereço: </strong>
              <label>{this.state.data.contratante.endereco}</label>
            </div>

            <div className="item">
              <strong>Estado: </strong>
              <label>{this.state.data.contratante.estado}</label>
            </div>

            <div className="item">
              <strong>Cep: </strong>
              <label>{this.state.data.contratante.cep}</label>
            </div>
          </div>

          <br />

          <strong className="ttl" style={{ marginTop: 30 }}>
            4 - CARACTERIZAÇÃO DA EMPRESA EXECUTANTE
          </strong>
          <div className="img-slc">
            <img src={`${apiUrl}/img/${this.state.data.executante.logo}`} />
          </div>
          <div className="dados">
            <div className="item">
              <strong>Razão social </strong>
              <label>{this.state.data.executante.razao_social}</label>
            </div>

            <div className="item">
              <strong>Cnpj: </strong>
              <label>{this.state.data.executante.cnpj}</label>
            </div>

            <div className="item">
              <strong>Endereço: </strong>
              <label>{this.state.data.executante.endereco}</label>
            </div>

            <div className="item">
              <strong>Estado: </strong>
              <label>{this.state.data.executante.estado}</label>
            </div>

            <div className="item">
              <strong>Cep: </strong>
              <label>{this.state.data.executante.cep}</label>
            </div>

            <div className="item">
              <strong>Telefone: </strong>
              <label>{this.state.data.executante.telefone}</label>
            </div>

            <div className="item">
              <strong>Email: </strong>
              <label>{this.state.data.executante.email}</label>
            </div>
          </div>
        </div>
      </>,
      <>
        <div className="dados">
          <strong>5- RESPONSÁVEIS TÉCNICOS</strong>

          <div className="rps" style={{ marginTop: 10 }}>
            {this.state.data.tecs.map((item) => (
              <div>
                <strong>Responsávels Técnico: </strong>
                <label>
                  {item.nome} - {item.func}
                </label>
              </div>
            ))}
          </div>

          <div style={{ fontSize: 14, marginTop: 30 }}>
            A responsabilidade técnica é exclusivamente para a elaboração da
            apreciação de riscos mediante ao recolhimento de ART (anotação de
            responsabilidade técnica).
          </div>
        </div>
      </>,
      <>
        <div className="ttl" style={{ marginTop: 30 }}>
          6- ESCOPO
        </div>

        <div className="dados">
          <div>
            O objetivo deste documento é a elaboração da apreciação de riscos de
            máquinas e equipamentos expondo todos os perigos existentes para que
            possam ser regularizados posteriormente, ou seja, a segurança
            absoluta não é um estado completamente acessível e, portanto, o
            objetivo é atingir o mais alto nível de segurança possível,
            levando-se em conta o estado da técnica. A utilização dos métodos
            elencados nas páginas a seguir visa ações de melhoria a fim de
            mitigar ou eliminar os riscos existentes na operação baseando-se nas
            normas oficiais vigentes{" "}
          </div>
          <strong>
            NR12 (NR-12 – SEGURANÇA NO TRABALHO EM MÁQUINAS E EQUIPAMENTOS),
            ABNT NBR ISO 12100 – Segurança de máquinas – Princípios gerais de
            projeto – Apreciação e redução de riscos.
          </strong>
        </div>
      </>,
      <>
        <div className="ttl" style={{ marginTop: 30 }}>
          7- MÉTODO DE AVALIAÇÃO PARA ESTIMATIVA DE RISCO CONFORME METODOLOGIA
          HAZARD RATING NUMBER (HRN)
        </div>
        <div className="dados">
          <div>
            Foi utilizado como ferramenta para quantificação e graduação do
            risco o método chamado Hazard Rating Number (HRN), ou seja, Número
            de Avaliação de Perigos. Este método é usado para classificar um
            risco entre Aceitável a Extremo, dando ao risco uma nota baseada em
            diversos fatores e parâmetros.
          </div>
          <div style={{ marginTop: 10 }}>
            Os parâmetros utilizados por este método são:
          </div>
          <ul>
            <li>
              A probabilidade de ocorrência (LO) de estar em contato com o risco
            </li>
            <li>A frequência de exposição ao risco (FE) </li>
            <li>O grau de severidade do dano (DPH) </li>
            <li>O número de pessoas exposta ao risco (NP) </li>
          </ul>
          <div>
            Para cada item mencionado acima é estabelecido um número que
            representa a variável de cálculo usada para encontrar o HRN do risco
            ou item avaliado. A formula aplicada para encontrar o nível de risco
            quantificado é a seguinte:
          </div>
          <div style={{ textAlign: "center", marginTop: 10 }}>
            <strong>HRN = NP x DPH x FE x LO</strong>
          </div>
          <div style={{ marginTop: 10 }}>
            Os parâmetros mencionados assim como as variáveis que cada um
            representa estão mencionados na tabela que se segue:{" "}
          </div>
        </div>
        <div className="d-flex justify-content-center mt-2">
          <img src={f1} style={{ width: "60%", height: "auto" }} />
        </div>
        <div className="mt-2 mb-2">
          <strong>RESULTADO DO CÁLCULO:</strong>
        </div>
        <div style={{ display: "table", margin: "auto" }}>
          <img src={f2} style={{ width: "100%", height: "100%" }} />
        </div>
      </>,

      <>
        <div className="ttl" style={{ marginTop: 30 }}>
          8- DETERMINAÇÃO DA CATEGORIA
        </div>

        <div className="dados">
          <div>
            Quando a máquina possuir a necessidade de uma solução de segurança
            com a utilização de sistemas eletrônicos (interfaces, sensores e
            atuadores) deve se atentar em qual categoria de segurança os riscos
            serão controlados. Para tais informações deve se levar em
            consideração os parâmetros de
            <strong>
              {` `}Severidade do ferimento, Frequência e/ou tempo de exposição
              ao perigo e Possibilidade de evitar o perigo (citados nas normas)
              ISSO 12100: 2013, ISO/TR 14.121-2: 2012 e NBR 14153: 2022, estas
              normas são utilizadas como base para a elaboração desta apreciação
              de riscos.
            </strong>
            <div
              style={{ marginTop: 10, marginBottom: 10, textAlign: "center" }}
            >
              <strong>NBR 14153: 2022</strong>
            </div>
            <div style={{ display: "table", margin: "auto" }}>
              <img src={f3} style={{ with: 600, height: "100%" }} />
            </div>
            <div>
              <strong>Os critérios de seleção:</strong>
            </div>
            <div style={{ marginTop: 10 }}>
              <strong>S – Severidade do ferimento </strong>
            </div>
            <ul>
              <li>S1: Ferimento leve (normalmente reversível)</li>
              <li>
                S2: Ferimento sério (normalmente irreversível) incluindo morte{" "}
              </li>
            </ul>
            <div style={{ marginTop: 10 }}>
              <strong>F – Frequência e/ou tempo de exposição ao perigo</strong>
            </div>
            <ul>
              <li>
                F1: Raro a relativamente frequente e/ou baixo tempo de exposição
              </li>
              <li>F2: Frequente a contínuo e/ou tempo de exposição longo </li>
            </ul>
            <div style={{ marginTop: 10 }}>
              <strong>P – Possibilidade de evitar o perigo</strong>
            </div>
            <ul>
              <li>P1: Possível sob condições específicas</li>
              <li>P2: Quase nunca possível. </li>
            </ul>
          </div>
        </div>
      </>,
      <>
        <div className="ttl" style={{ marginTop: 30 }}>
          As categorias de Segurança: <br />
          <br />
          Categoria B
        </div>

        <div className="dados">
          <div>
            As partes relacionadas à segurança devem ser projetadas de tal forma
            que resistam:
          </div>

          <div style={{ marginTop: 10 }}>
            Fadiga operacional prevista, como, por exemplo, a confiabilidade com
            respeito à capacidade e frequência de comutação;
          </div>

          <div style={{ marginTop: 10 }}>
            {" "}
            — Influência do material processado ou utilizado no processo, como,
            por exemplo, detergentes em máquinas de lavar;
          </div>
          <div style={{ marginTop: 10 }}>
            — Outras influências externas relevantes, como, por exemplo,
            vibrações mecânicas, campos externos, distúrbios ou interrupção do
            fornecimento de energia.
          </div>
          <div style={{ display: "table", margin: "auto", marginTop: 50 }}>
            <img src={f4} />
          </div>

          <div style={{ marginTop: 20 }}>
            <strong>Categoria 1</strong>
            <div className="aln-t">
              <p>Os requisitos de B se aplicam.</p>

              <p>
                Princípios comprovados e componentes de segurança bem testados
                devem ser utilizados.
              </p>

              <p>
                As partes de sistemas de comando relacionadas à segurança, de
                categoria 1, devem ser projetadas e construídas utilizando-se
                componentes bem ensaiados e princípios de segurança comprovados.
              </p>

              <p>
                Um componente bem ensaiado para uma aplicação relacionada à
                segurança é aquele que tem sido:
              </p>

              <ul>
                <p>
                  Largamente empregado no passado, com resultados satisfatórios
                  em aplicações similares ou Construído e verificado
                  utilizando-se princípios que demonstrem sua adequação e
                  confiabilidade para aplicações relacionadas à segurança.
                </p>
              </ul>
            </div>
          </div>
        </div>
        <div style={{ display: "table", margin: "auto", marginTop: 10 }}>
          <img src={f8} />
        </div>
      </>,
      <>
        <div></div>

        <div style={{ marginBottom: 10, marginTop: 10 }}>
          <strong>Categoria 2</strong>
        </div>

        <div style={{ textAlign: "justify", marginRight: 10 }}>
          <div>
            As partes de sistemas de comando relacionadas à segurança, de
            categoria 2, devem ser projetadas de tal forma que sejam verificadas
            em intervalos adequados pelo sistema de comando da máquina.
          </div>
          <div>
            As verificações das funções de segurança devem ser efetuadas:
          </div>
          <div>
            - Na partida da máquina e antes do início de qualquer situação de
            perigo, e
          </div>
          <div>
            - Periodicamente durante a operação, se a avaliação do risco e o
            tipo de operação mostrarem que isso é necessário.
          </div>
        </div>

        <div style={{ display: "table", margin: "auto", marginTop: 10 }}>
          <img src={f10} />
        </div>
        <div style={{ marginBottom: 10, marginTop: 10 }}>
          <strong>Categoria 3</strong>
        </div>
        <div style={{ textAlign: "justify", marginRight: 10 }}>
          <div>
            Partes relacionadas à segurança de sistemas de comando de categoria
            3 devem ser projetadas de tal forma que um defeito isolado, em
            qualquer dessas partes, não leve à perda das funções de segurança.
            Defeitos de modos comuns devem ser considerados, quando a
            probabilidade da ocorrência de tal defeito for significante. Sempre
            que razoavelmente praticável, o defeito isolado deve ser detectado
            durante ou antes da próxima solicitação da função de segurança.
          </div>
        </div>
        <div style={{ display: "table", margin: "auto", marginTop: 10 }}>
          <img src={f11} />
        </div>
      </>,
      <>
        <div style={{ textAlign: "justify", marginRight: 10 }}>
          <div></div>
        </div>

        <div style={{ marginBottom: 20, marginTop: 20 }}>
          <strong>Categoria 4</strong>
        </div>
        <div style={{ textAlign: "justify", marginRight: 10 }}>
          <div>
            Partes de sistemas de comando relacionadas à segurança, de categoria
            4, devem ser projetadas de tal forma que:
          </div>
          <div>
            - Uma falha isolada em qualquer dessas partes relacionadas à
            segurança não leve à perda das funções de segurança, e
          </div>
          <div>
            - A falha isolada seja detectada antes ou durante a próxima atuação
            sobre a função de segurança, como, por exemplo, imediatamente, ao
            ligar o comando, ao final do ciclo de operação da máquina.
          </div>
          <div>
            Se essa detecção não for possível, o acúmulo de defeitos não pode
            levar à perda das funções de segurança.
          </div>
        </div>
        <div style={{ display: "table", margin: "auto", marginTop: 10 }}>
          <img src={ctg4} />
        </div>
        <div></div>
        <div className="centro">
          <img src={f5} style={{ width: "100%", height: "60%" }} />
        </div>
        <div style={{ textAlign: "justify", marginRight: 10 }}>
          <div>
            Este gráfico de riscos pode ser utilizado para estimar um índice de
            riscos principalmente para situações perigosas que podem induzir
            danos agudos.
          </div>
          <div style={{ padding: 15 }}>
            <div>
              <strong>
                A. Um índice de risco de 1 e 2 corresponde a um risco baixo;
              </strong>
            </div>
            <div>
              <strong>
                B. Um índice de risco de 3 a 4 corresponde a um risco médio;
              </strong>
            </div>
            <div>
              <strong>
                C. Um índice de risco de 5 e 6 corresponde a um risco alto;
              </strong>
            </div>
          </div>
          <div>
            Depois de uma análise dos possíveis meios para reduzir o risco, ele
            é estimado novamente para o projeto final utilizando o mesmo gráfico
            de riscos da mesma forma que para o projeto inicial.{" "}
          </div>
        </div>
      </>,
      <>
        <div className="centro">
          <img src={f6} style={{ width: "100%", height: "100%" }} />
        </div>
        <div className="dados">
          <div>
            {" "}
            Estabelecendo uma relação de semelhança entre HRN metodologia
            aplicada e as normas técnicas{" "}
            <strong>
              NBR 14153: 2022, ISO/TR 14.121-2: 2018 e ISO 12.100: 2013
            </strong>{" "}
            é possível chegar a uma conclusão para a determinação de categoria
            de segurança:
          </div>

          <div style={{ marginTop: 10, marginBottom: 10 }}>
            Pode se considerar um risco baixo a categoria 1, e um risco médio
            quando se encaixa na categoria 2 e um risco alto, quando está
            classificado na categoria 3 ou 4.
          </div>
          <div style={{ textAlign: "center" }}>
            <strong> ISO 13849-1: 2019</strong>
          </div>
          <div>
            Qualquer que seja o tipo de dispositivo de proteção escolhido,
            deve-se lembrar que um “sistema relacionado à segurança” pode conter
            muitos elementos incluindo o dispositivo de proteção, fiação,
            dispositivo de chaveamento e às vezes, peças do sistema de controle
            operacional da máquina. Todos esses elementos do sistema (incluindo
            proteções, montagem, fios, etc.) devem ter características de
            desempenho adequadas, relevantes ao seu princípio de projeto e
            tecnologia. Os <strong>IEC/EM 62061 e EM ISO 13849-1</strong>{" "}
            classificam os níveis hierárquicos de desempenho das peças
            relacionadas à segurança dos sistemas de controle e proporcionam
            métodos de avaliação de risco em seus anexos para determinar os
            requisitos de integridade de um sistema de proteção. O{" "}
            <strong>EN ISO 13849-1: 2015</strong> fornece um gráfico de risco
            aumentado em seu anexo A.
          </div>
        </div>
      </>,

      <>
        <div className="centro">
          <img src={f7} style={{ width: "100%", height: "100%" }} />
        </div>

        <div>
          <strong>Onde:</strong>
        </div>
        <div style={{ padding: 10 }}>
          <div>
            <strong>1-</strong> Ponto de partida para a avaliação de redução de
            risco.
          </div>
          <div>
            <strong>L-</strong> Baixa contribuição para a redução do risco.
          </div>
          <div>
            <strong>H-</strong> Alta contribuição para a redução do risco.
          </div>
          <div>
            <strong>PLr-</strong> Performance Level requerido.
          </div>
        </div>
        <div style={{ textAlign: "center" }}>
          <strong>S – Severidade do ferimento</strong>
        </div>
        <div style={{ padding: 10 }}>
          <div>
            <strong>S1-</strong>Ferimento leve (normalmente reversível)
          </div>
          <div>
            <strong>S2-</strong> Ferimento sério (normalmente irreversível)
            incluindo morte.
          </div>
        </div>

        <div style={{ textAlign: "center" }}>
          <strong>F – Frequência e/ou tempo de exposição ao perigo.</strong>
        </div>
        <div style={{ padding: 10 }}>
          <div>
            <strong>F1-</strong>Raro a relativamente frequente e/ou baixo tempo
            de exposição.
          </div>
          <div>
            <strong>F2-</strong> Frequente a contínuo e/ou tempo de exposição
            longo.
          </div>
        </div>

        <div style={{ textAlign: "center" }}>
          <strong>P – Possibilidade de evitar o perigo.</strong>
        </div>
        <div style={{ padding: 10 }}>
          <div>
            <strong>P1-</strong>Possível sob condições especificas.
          </div>
          <div>
            <strong>P2-</strong>Quase nunca possível.
          </div>
        </div>

        <div style={{ textAlign: "center" }}></div>
      </>,
      <div className="dados">
        <strong>EM/IEC 62061 – SIL</strong>
        <div>
          Descreve a quantidade de risco a ser reduzido e a capacidade de um
          sistema de controle de reduzir esse risco em termos de SIL (Nível de
          Integridade de Segurança). Há três SILs usados no setor de máquinas, o
          SIL 1 é o menor e o SIL 3 é o maior. Como o termo SIL é aplicado do
          mesmo modo em outros setores industriais, como o petroquímico, geração
          de energia e ferrovias, o IEC/EM 62061 é bastante útil quando a
          máquina é usada nesses setores.
        </div>
        <div className="centro">
          <img src={g1} style={{ width: "100%", height: 250 }} />
        </div>

        <div>
          Relação entre os dois padrões e explica como a equivalência pode ser
          obtida entre PL (Nível de Desempenho) do EM ISO 13849-1 e SIL (Nível
          de Integridade de Segurança) do IEC EM 62061, ambos no nível de
          sistema e subsistema. Para demonstrar que ambos os padrões fornecem
          resultados equivalentes, o relatório exibe um sistema de segurança
          como exemplo, calculado de acordo com as metodologias de ambos os
          padrões.
        </div>

        <div style={{ marginTop: 20 }}>
          Em geral, quando PLe se faz necessário para a função de segurança ser
          implementada por um sistema de controle de segurança, não é normal
          depender somente de exclusões de falhas para atingir esse nível de
          desempenho. Isso depende da tecnologia usada e do ambiente operacional
          pretendido. Portanto, é essencial que o projetista tome cuidado
          adicional com o uso de exclusões de falhas à medida que a exigência de
          PL aumenta.
        </div>

        <div style={{ textAlign: "center", marginBottom: 10, marginTop: 10 }}>
          <strong>RELAÇÃO ENTRE NBR 14153 E A ISO 13849-1: 2019</strong>
        </div>

        <div>
          A norma ISO 13849-1 usa dados quantitativos de confiabilidade como
          parte do cálculo do PL alcançado pelas partes relacionadas com a
          segurança de um sistema de controle. Estes dados devem ter
          confiabilidade assegurada, pois estamos falando, por exemplo, de um
          MTTFd alto (tempo médio para ocorrência da falha perigosa) na faixa de
          30 a 100 anos.
        </div>

        <div>
          Na União Europeia a confiabilidade dos componentes de segurança está
          coberta pela avaliação da conformidade expressa na Diretiva Máquinas,
          mesmo assim, a migração dos conceitos da EM 954
          <div>
            (base da ABNT NBR 14153) para ISSO 13849 passou por um longo
            processo de maturação, inclusive com a convivência por mais de 2
            anos, de opção para aplicação de qualquer uma das duas normas.
          </div>
        </div>
      </div>,
      <div className="dados">
        <div style={{ textAlign: "center", marginBottom: 25, marginTop: 25 }}>
          <strong>
            RELAÇÃO ENTRE CATEGORIAS, DC E MTTFD DE CADA CANAL E O PL.
          </strong>
        </div>

        <div>
          Conclui-se que para o alcance do nível de performance requerido, não
          basta apenas a arquitetura do sistema (categorias), mas também
          trabalhar as variáveis como a confiabilidade dos dados para as partes
          constituintes do sistema expresso no tempo médio para falha perigosa
          (MTTFd), a cobertura de diagnostico (DC) que representa a quantidade
          de monitoramento de falhas no sistema, proteção contra falhas de causa
          comum, proteção contra falhas sistemáticas e, sempre que necessário,
          requisitos específicos de software.
        </div>
        <br />
        <div>
          O gráfico e a tabela abaixo procuram estabelecer, de forma
          simplificada, a relação entre as categorias de segurança e PL em
          função das variáveis elencadas.
        </div>
        <div className="centro">
          <img src={g2} />
        </div>

        <div className="centro">
          <img src={g3} />
        </div>
      </div>,
      <div className="dados">
        <div className="centro">
          <img src={g4} />
        </div>
      </div>,
      <>
        <div>
          <strong>9- DOCUMENTAÇÃO DO EQUIPAMENTO:</strong>
          <br />
        </div>
        <div class="aln9">
          <table className="ntbl table-bordered">
            <thead>
              <tr>
                <th rowspan="2">DOCUMENTOS</th>
                <th colspan="3">STATUS</th>
                <th rowspan="2">OBSERVAÇÕES</th>
              </tr>
              <tr>
                <th>OK</th>
                <th>NÃO OK</th>
                <th>N.A.</th>
              </tr>
            </thead>
            <tbody>
              {[
                "ANOTAÇÃO DE RESPONSABILIDADE TÉCNICA",
                "CHECKLIST DE MANUTENÇÃO PREVENTIVA",
                "CHECKLIST DE SEGURANÇA",
                "ART DIAGRAMA DE CIRCUITOS ELÉTRICOS",
                "DIAGRAMA HIDRÁULICO",
                "DIAGRAMA PNEUMÁTICO",
                "INSTRUÇÕES DE TRABALHO",
                "LAUDO DE ATERRAMENTO",
                "LAUDO DE TEMPO DE PARADA",
                "MANUAL DE INSTRUÇÕES DE MANUTENÇÃO",
                "MANUAL DE INSTRUÇÕES DE SEGURANÇA",
                "MANUAL DE INSTRUÇÕES OPERACIONAIS",
                "PLAQUETA DE IDENTIFICAÇÃO",
                "ART PROJETO MECÂNICO",
              ].map((item, index) => {
                var check = this.state.data.form.find(
                  (u) => u.e === `${index}`
                );
                if (check != undefined) {
                  var find = check;
                } else {
                  var find = {};
                }
                return (
                  <>
                    <tr>
                      <td>{item}</td>
                      <td>
                        <label>
                          <input
                            type="radio"
                            checked={find.marker === "0"}
                            name={`${index}`}
                            value={0}
                          />
                        </label>
                      </td>

                      <td class="highlight">
                        <input
                          type="radio"
                          checked={find.marker === "1"}
                          name={`${index}`}
                          value={1}
                        />
                      </td>

                      <td>
                        <input
                          checked={find.marker === "2"}
                          type="radio"
                          name={`${index}`}
                          value={2}
                        />
                      </td>

                      <td class="highlight">
                        {find.obs == undefined ? "Não evidenciado" : find.obs}
                      </td>
                    </tr>
                  </>
                );
              })}
            </tbody>
          </table>
        </div>
      </>,
    ];

    var itens2 = [
      <>
        <div>
          <strong>11 - DEFINIÇÃO DA CATEGORIA DE SEGURANÇA</strong>
          <div style={{ marginTop: 5 }}>
            A máquina foi avaliada e categorizada como categoria{" "}
            <strong>{this.state.ctg}</strong> e Performance Level Required{" "}
            <strong>{this.state.letra.toUpperCase()}</strong> de segurança
            conforme: EM ISO 13849-1/NBR 14153.
          </div>

          <div className="area-diag">
            <div className="diagrama d-preview">
              Categoria de segurança
              <div className="">
                {/*<TreeNode
save={this.state.data.diagram} node={{
  name: 'Start',
  id:0,
  children: [
    {
      name: 's1',
      id:1,
      children: [
        {
          name: 'f1',
          id:2,
          children: [
            { name: 'p1',id:3 },
            { name: 'p2',id:4 },
          ],
        },
        { name: 'f2',
          id:5,
          children: [
            { name: 'p1',id:6 },
            { name: 'p2',id:7 },
          ],

        },
      ],
    },
    {
      name: 's2',
      id:8,
      children: [
        {
          name: 'f1',
          id:9,
          children: [
            { name: 'p1',id:10 },
            { name: 'p2',id:11 },
          ],
        },
        {
          name: 'f2',
          id:12,
          children: [
            { name: 'p1',id:13 },
            { name: 'p2',id:14 },
          ],
        },
      ],
    },
  ],

}}
/>*/}

                <div className="linhas" style={{ marginTop: 15 }}>
                  <div className="l0">
                    <label>Start</label>
                  </div>
                  <div className="l1">
                    <label
                      className={
                        this.state.diagram.includes("s1-1") && "selected"
                      }
                    >
                      S1
                    </label>
                    <label
                      className={
                        this.state.diagram.includes("s2-8") && "selected"
                      }
                    >
                      S2
                    </label>

                    <div className="la">
                      <label
                        className={
                          this.state.diagram.includes("f1-2") && "selected"
                        }
                      >
                        F1
                      </label>
                      <label
                        className={
                          this.state.diagram.includes("f2-5") && "selected"
                        }
                      >
                        F2
                      </label>

                      <div className="top">
                        <label
                          className={
                            this.state.diagram.includes("p1-3") && "selected"
                          }
                        >
                          P1
                        </label>
                        <label
                          className={
                            this.state.diagram.includes("p2-4") && "selected"
                          }
                        >
                          P2
                        </label>
                      </div>

                      <div className="bot">
                        <label
                          className={
                            this.state.diagram.includes("p1-6") && "selected"
                          }
                        >
                          P1
                        </label>
                        <label
                          className={
                            this.state.diagram.includes("p2-7") && "selected"
                          }
                        >
                          P2
                        </label>
                      </div>
                    </div>

                    <div className="lb">
                      <label
                        className={
                          this.state.diagram.includes("f1-9") && "selected"
                        }
                      >
                        F1
                      </label>
                      <label
                        className={
                          this.state.diagram.includes("f2-12") && "selected"
                        }
                      >
                        F2
                      </label>

                      <div className="top">
                        <label
                          className={
                            this.state.diagram.includes("p1-10") && "selected"
                          }
                        >
                          P1
                        </label>
                        <label
                          className={
                            this.state.diagram.includes("p2-11") && "selected"
                          }
                        >
                          P2
                        </label>
                      </div>

                      <div className="bot">
                        <label
                          className={
                            this.state.diagram.includes("p1-13") && "selected"
                          }
                        >
                          P1
                        </label>
                        <label
                          className={
                            this.state.diagram.includes("p2-14") && "selected"
                          }
                        >
                          P2
                        </label>
                      </div>
                    </div>
                  </div>

                  <div className="letras">
                    <div className="a">
                      <span>a</span>
                    </div>

                    <div className="b">
                      <span>b</span>
                    </div>

                    <div className="c">
                      <span>c</span>
                    </div>

                    <div className="d">
                      <span>d</span>
                    </div>

                    <div className="e">
                      <span>e</span>
                    </div>
                  </div>
                  <div className="c-preview">
                    <div
                      className={
                        ["s1-1", "f1-2", "p1-3"].every(
                          (value, index) => value === this.state.diagram[index]
                        )
                          ? "f1-p1 selected"
                          : ""
                      }
                    />

                    <div
                      className={
                        ["s1-1", "f1-2", "p2-4"].every(
                          (value, index) => value === this.state.diagram[index]
                        )
                          ? "f1-p2 selected"
                          : ""
                      }
                    />

                    <div
                      className={
                        ["s1-1", "f2-5", "p1-6"].every(
                          (value, index) => value === this.state.diagram[index]
                        )
                          ? "f2-p1 selected"
                          : ""
                      }
                    />

                    <div
                      className={
                        ["s1-1", "f2-5", "p2-7"].every(
                          (value, index) => value === this.state.diagram[index]
                        )
                          ? "f2-p2 selected"
                          : ""
                      }
                    />

                    <div
                      className={
                        ["s2-8", "f1-9", "p1-10"].every(
                          (value, index) => value === this.state.diagram[index]
                        )
                          ? "s2-f1-p1 selected"
                          : ""
                      }
                    />

                    <div
                      className={
                        ["s2-8", "f2-12", "p1-13"].every(
                          (value, index) => value === this.state.diagram[index]
                        )
                          ? "s2-f2-p1 selected"
                          : ""
                      }
                    />

                    <div
                      className={
                        ["s2-8", "f1-9", "p2-11"].every(
                          (value, index) => value === this.state.diagram[index]
                        )
                          ? "s2-f1-p2 selected"
                          : ""
                      }
                    />

                    <div
                      className={
                        ["s2-8", "f2-12", "p2-14"].every(
                          (value, index) => value === this.state.diagram[index]
                        )
                          ? "s2-f2-p2 selected"
                          : ""
                      }
                    />
                  </div>
                  <div class="triangulo">
                    <img src={triag} />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div style={{ display: "table", margin: "auto", marginTop: 50 }}>
            <img src={g6} />
          </div>
        </div>
      </>,
      <div className="dados">
        <div className="ajusta-frm">
          <div class="texto">
            <strong>CONFORME NBR14153:2022</strong>
          </div>
          <div class="grf2" style={{ marginBottom: 10 }}>
            <div className="l0">
              <span
                className={this.state.diagram.includes("s1-1") && "selected"}
              >
                S1
              </span>
            </div>

            <div className="l1">
              <div class="gf0">
                <label
                  className={this.state.diagram.includes("s2-8") && "selected"}
                >
                  S2
                </label>
              </div>

              <div className="gf1">
                <label
                  className={this.state.diagram.includes("f1-9") && "selected"}
                >
                  F1
                </label>

                <label
                  className={this.state.diagram.includes("f2-12") && "selected"}
                >
                  F2
                </label>
              </div>

              <div className="fn">
                <div>
                  <label
                    className={
                      this.state.diagram.includes("p1-10") && "selected"
                    }
                  >
                    P1
                  </label>

                  <label
                    className={
                      this.state.diagram.includes("p2-11") && "selected"
                    }
                  >
                    P2
                  </label>
                </div>

                <div>
                  <label
                    className={
                      this.state.diagram.includes("p1-13") && "selected"
                    }
                  >
                    P1
                  </label>

                  <label
                    className={
                      this.state.diagram.includes("p2-14") && "selected"
                    }
                  >
                    P2
                  </label>
                </div>
              </div>
            </div>

            <table className="frm2" border="1" style={{ marginBottom: 10 }}>
              <div className="ctg-t">CATEGORIA</div>
              <thead>
                <tr className="alinha-f2">
                  {this.state.tabela[0].map((header, index) => (
                    <th key={index}>{header}</th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {this.state.tabela.slice(1).map((row, rowIndex) => (
                  <tr key={rowIndex}>
                    {row.map((item, colIndex) => (
                      <td key={colIndex}>
                        <button
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            backgroundColor: selectedItems.includes(
                              `${rowIndex}-${colIndex}`
                            )
                              ? "yellow"
                              : "transparent",
                            borderRadius: "50%",
                            padding: "10px",
                            width: "40px",
                            height: "40px",
                          }}
                        >
                          {item}
                        </button>
                      </td>
                    ))}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>

        <div style={{ display: "table", margin: "auto", marginTop: 40 }}>
          <img src={g8} />
        </div>
      </div>,
      <>
        <div className="dados">
          <div>
            <strong>12-AVALIAÇÃO – APRECIAÇÃO DE RISCOS</strong>
          </div>
          <div style={{ marginLeft: 50, marginTop: 10 }}>
            <strong style={{ fontSize: 18 }}>
              12.1 - Arranjo físico das instalações – Geral
            </strong>
          </div>
          <div style={{ marginTop: 5 }}>
            <p>
              Nos locais de instalações de máquinas e equipamentos, as áreas de
              circulação devem ser devidamente demarcadas e em conformidade com
              as normas técnicas oficias vigentes.
            </p>
          </div>
          <div className="mb-2">
            <strong>Situação atual</strong>
          </div>

          {apc2Item1 && apc2Item1.itens.length > 0 && (
            <>
              <div>
                {apc2Item1.itens[0]?.text
                  ? apc2Item1.itens[0].text
                      .split("\n")
                      .map((line, index) => <p key={index}>{line}</p>)
                  : null}
              </div>

              <div className="d-flex justify-content-center mt-2">
                {apc2Item1.itens[0].img1 != null && (
                  <>
                    <img
                      src={`${apiUrl}/img/${apc2Item1.itens[0].img1}`}
                      style={{
                        width: 250,
                        height: 250,
                        marginRight: 20,
                        border: "solid 1px",
                      }}
                      alt="image not loaded"
                    />
                  </>
                )}

                {apc2Item1.itens[0].img2 != null && (
                  <>
                    <img
                      src={`${apiUrl}/img/${apc2Item1.itens[0].img2}`}
                      style={{ width: 250, height: 250, border: "solid 1px" }}
                      alt="image not loaded"
                    />
                  </>
                )}
              </div>

              <div className="d-flex justify-content-center mt-2">
                {apc2Item1.itens[0].img3 != null && (
                  <>
                    <img
                      src={`${apiUrl}/img/${apc2Item1.itens[0].img3}`}
                      style={{
                        width: 250,
                        height: 250,
                        marginRight: 20,
                        border: "solid 1px",
                      }}
                      alt="image not loaded"
                    />
                  </>
                )}

                {apc2Item1.itens[0].img4 != null && (
                  <>
                    <img
                      src={`${apiUrl}/img/${apc2Item1.itens[0].img4}`}
                      style={{ width: 250, height: 250, border: "solid 1px" }}
                      alt="image not loaded"
                    />
                  </>
                )}
              </div>
            </>
          )}

          {apc2Item1.itens.length < 2 && (
            <>
              <RiskAppreciationTable
                code="hrn-1"
                data={this.state.FormHrn}
                resp={(value) => {
                  this.setState((prevState) => ({
                    AtMlhs: [...prevState.AtMlhs, value],
                  }));
                }}
                title="APRECIAÇÃO DE RISCO ANTES DAS AÇÕES DE MELHORIAS"
              />
            </>
          )}
        </div>
      </>,
    ];
    const resulta0 =
      this.state.resulta[0].itens.length > 1
        ? this.state.resulta[0].itens.slice(1).map((item, index) => (
            <>
              <div style={{ padding: 10 }}>
                <div style={{ border: "solid 1px", marginTop: 15 }}>
                  <div style={{ margin: 5 }}>
                    {item.t3 != null &&
                      item.t3
                        .split("\n")
                        .map((line, index) => <p key={index}>{line}</p>)}

                    {item.t1 != null && (
                      <>
                        <p>
                          {item.t1.split("\n").map((line, index) => (
                            <p key={index}>{line}</p>
                          ))}
                        </p>
                      </>
                    )}

                    <div className="d-flex justify-content-center mt-3">
                      {item.i1 != null && (
                        <>
                          <div className="mb-2">
                            <img
                              src={`${apiUrl}/img/${item.i1}`}
                              style={{
                                width: 250,
                                height: 250,
                                border: "solid 1px",
                                marginRight: 20,
                              }}
                            />
                          </div>
                        </>
                      )}

                      {item.i2 != null && (
                        <>
                          <div className="mb-2">
                            <img
                              src={`${apiUrl}/img/${item.i2}`}
                              style={{
                                width: 250,
                                height: 250,
                                border: "solid 1px",
                                marginLeft: 20,
                              }}
                            />
                          </div>
                        </>
                      )}
                    </div>
                    {item.t2 != null && (
                      <>
                        <p>
                          {item.t2.split("\n").map((line, index) => (
                            <p key={index}>{line}</p>
                          ))}
                        </p>
                      </>
                    )}
                    <div className="d-flex justify-content-center mt-2">
                      {item.i3 != null && (
                        <>
                          <div className="mb-2">
                            <img
                              src={`${apiUrl}/img/${item.i3}`}
                              style={{
                                width: 250,
                                height: 250,
                                border: "solid 1px",
                                marginRight: 20,
                              }}
                            />
                          </div>
                        </>
                      )}

                      {item.i4 != null && (
                        <>
                          <div className="mb-2">
                            <img
                              src={`${apiUrl}/img/${item.i4}`}
                              style={{
                                width: 250,
                                height: 250,
                                border: "solid 1px",
                                marginLeft: 20,
                              }}
                            />
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                </div>

                {index === this.state.resulta[0].itens.length - 2 && (
                  <>
                    <RiskAppreciationTable
                      code="hrn-2"
                      data={this.state.FormHrn}
                      resp={(value) => {
                        this.setState((prevState) => ({
                          DpMlhs: [...prevState.DpMlhs, value],
                        }));
                      }}
                      title="APRECIAÇÃO DE RISCO DEPOIS DAS AÇÕES DAS MELHORIAS"
                    />
                  </>
                )}
              </div>
            </>
          ))
        : [];
    var itens3za = [
      <div className="dados">
        {this.state.resulta[0].itens.length == 0 ? (
          <>
            <div style={{ border: "solid 1px", marginTop: 15 }}>
              <div style={{ textAlign: "center", background: "#bdd6ee" }}>
                <strong>
                  AÇÕES PROPOSTAS COMO SUGESTÃO DE ADEQUAÇÃO -{" "}
                  {process.env.NODE_ENV === "development" && "1"}
                </strong>
              </div>
              <div style={{ margin: 5 }}>Vázio!</div>
            </div>
          </>
        ) : (
          <>
            <div style={{ border: "solid 1px", marginTop: 15 }}>
              <div style={{ textAlign: "center", background: "#bdd6ee" }}>
                <strong>
                  AÇÕES PROPOSTAS COMO SUGESTÃO DE ADEQUAÇÃO{" "}
                  {process.env.NODE_ENV === "development" && "2"}
                </strong>
              </div>
              <div style={{ margin: 5 }}>
                {this.state.resulta[0].itens[0].t1 != null && (
                  <>
                    {this.state.resulta[0].itens[0].t1
                      .split("\n")
                      .map((line, index) => (
                        <p key={index}>{line}</p>
                      ))}
                  </>
                )}

                <div className="d-flex justify-content-center mb-3">
                  {this.state.resulta[0].itens[0].i1 != null && (
                    <>
                      <div className="mb-2">
                        <img
                          src={`${apiUrl}/img/${this.state.resulta[0].itens[0].i1}`}
                          style={{
                            width: 250,
                            height: 250,
                            border: "solid 1px",
                            marginRight: 25,
                          }}
                        />
                      </div>
                    </>
                  )}

                  {this.state.resulta[0].itens[0].i2 != null && (
                    <>
                      <div className="mb-2">
                        <img
                          src={`${apiUrl}/img/${this.state.resulta[0].itens[0].i2}`}
                          style={{
                            width: 250,
                            height: 250,
                            border: "solid 1px",
                          }}
                        />
                      </div>
                    </>
                  )}
                </div>
                {this.state.resulta[0].itens[0].t2 != null && (
                  <>
                    {this.state.resulta[0].itens[0].t2
                      .split("\n")
                      .map((line, index) => (
                        <p key={index}>{line}</p>
                      ))}
                  </>
                )}
                <div className="d-flex justify-content-center mb-3">
                  {this.state.resulta[0].itens[0].i3 != null && (
                    <>
                      <div className="mb-2">
                        <img
                          src={`${apiUrl}/img/${this.state.resulta[0].itens[0].i3}`}
                          style={{
                            width: 250,
                            height: 250,
                            border: "solid 1px",
                            marginRight: 25,
                          }}
                        />
                      </div>
                    </>
                  )}

                  {this.state.resulta[0].itens[0].i4 != null && (
                    <>
                      <div className="mb-2">
                        <img
                          src={`${apiUrl}/img/${this.state.resulta[0].itens[0].i4}`}
                          style={{
                            width: 250,
                            height: 250,
                            border: "solid 1px",
                          }}
                        />
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
          </>
        )}

        {resulta0.length < 1 && (
          <>
            <RiskAppreciationTable
              code="hrn-2"
              data={this.state.FormHrn}
              resp={(value) => {
                this.setState((prevState) => ({
                  DpMlhs: [...prevState.DpMlhs, value],
                }));
              }}
              title="APRECIAÇÃO DE RISCO DEPOIS DAS AÇÕES DAS MELHORIAS"
            />
          </>
        )}
      </div>,
    ];
    var itens3z = [
      <>
        <div style={{ marginLeft: 50, marginTop: 10 }}>
          <strong style={{ fontSize: 18 }}>
            12.2 - Instalações e dispositivos elétricos – Geral
          </strong>
        </div>

        <div>
          <p>
            Os circuitos elétricos de comando e potência das máquinas e
            equipamentos devem ser projetados e mantidos de modo a prevenir, por
            meios seguros, os perigos de choque elétrico, incêndio, explosão e
            outros tipos de acidentes, conforme previsto nas normas técnicas
            oficiais e, na falta dessas, nas normas internacionais aplicáveis.
          </p>
        </div>
        <div className="mb-2">
          <strong>Situação atual</strong>
        </div>
        {apc2Item2 && apc2Item2.itens.length > 0 && (
          <>
            <div>
              {apc2Item2.itens[0]?.text
                ? apc2Item2.itens[0].text
                    .split("\n")
                    .map((line, index) => <p key={index}>{line}</p>)
                : null}
            </div>
            <div className="d-flex justify-content-center mt-2">
              {apc2Item2.itens[0].img1 != null && (
                <>
                  <div className="mb-2">
                    <img
                      src={`${apiUrl}/img/${apc2Item2.itens[0].img1}`}
                      style={{
                        width: 250,
                        height: 250,
                        border: "solid 1px",
                        marginRight: 25,
                      }}
                    />
                  </div>
                </>
              )}
              {apc2Item2.itens[0].img2 != null && (
                <>
                  <div className="mb-2">
                    <img
                      src={`${apiUrl}/img/${apc2Item2.itens[0].img2}`}
                      style={{
                        width: 250,
                        height: 250,
                        border: "solid 1px",
                      }}
                    />
                  </div>
                </>
              )}
            </div>
            <div>
              {apc2Item2.itens[0]?.text2
                ? apc2Item2.itens[0].text2
                    .split("\n")
                    .map((line, index) => <p key={index}>{line}</p>)
                : null}
            </div>
            <div className="d-flex justify-content-center mt-2">
              {apc2Item2.itens[0].img3 != null && (
                <>
                  <div className="mb-2">
                    <img
                      src={`${apiUrl}/img/${apc2Item2.itens[0].img3}`}
                      style={{
                        width: 250,
                        height: 250,
                        border: "solid 1px",
                        marginRight: 25,
                      }}
                    />
                  </div>
                </>
              )}

              {apc2Item2.itens[0].img4 != null && (
                <>
                  <div className="mb-2">
                    <img
                      src={`${apiUrl}/img/${apc2Item2.itens[0].img4}`}
                      style={{
                        width: 250,
                        height: 250,
                        border: "solid 1px",
                      }}
                    />
                  </div>
                </>
              )}
            </div>
          </>
        )}
        {apc2Item2.itens.length < 2 && (
          <>
            <RiskAppreciationTable
              code="hrn-3"
              data={this.state.FormHrn}
              resp={(value) => {
                this.setState((prevState) => ({
                  AtMlhs: [...prevState.AtMlhs, value],
                }));
              }}
              title="APRECIAÇÃO DE RISCO ANTES DAS AÇÕES DAS MELHORIAS "
            />
          </>
        )}

        <div style={{ marginTop: 10 }}>
          <span>{this.TextView("5dzfg1")}</span>
        </div>
      </>,
    ];

    var itens3a = [
      <div class="dados">
        {this.state.resulta[1].itens.length > 0 ? (
          <>
            <div style={{ border: "solid 1px", marginTop: 15 }}>
              <div style={{ textAlign: "center", background: "#bdd6ee" }}>
                <strong>
                  AÇÕES PROPOSTAS COMO SUGESTÃO DE ADEQUAÇÃO{" "}
                  {process.env.NODE_ENV === "development" && "3"}
                </strong>
              </div>
              <div style={{ margin: 5 }}>
                {this.state.resulta[1].itens[0].t1 != null && (
                  <>
                    {this.state.resulta[1].itens[0].t1
                      .split("\n")
                      .map((line, index) => (
                        <p key={index}>{line}</p>
                      ))}
                  </>
                )}

                <div className="d-flex justify-content-center mb-3">
                  {this.state.resulta[1].itens[0].i1 != null && (
                    <>
                      <div className="mb-2">
                        <img
                          src={`${apiUrl}/img/${this.state.resulta[1].itens[0].i1}`}
                          style={{
                            width: 250,
                            height: 250,
                            border: "solid 1px",
                            marginRight: 25,
                          }}
                        />
                      </div>
                    </>
                  )}

                  {this.state.resulta[1].itens[0].i2 != null && (
                    <>
                      <div className="mb-2">
                        <img
                          src={`${apiUrl}/img/${this.state.resulta[1].itens[0].i2}`}
                          style={{ width: 250, height: 250 }}
                        />
                      </div>
                    </>
                  )}
                </div>
                {this.state.resulta[1].itens[0].t2 != null && (
                  <>
                    {this.state.resulta[1].itens[0].t2
                      .split("\n")
                      .map((line, index) => (
                        <p key={index}>{line}</p>
                      ))}
                  </>
                )}
                <div className="d-flex justify-content-center mb-3">
                  {this.state.resulta[1].itens[0].i3 != null && (
                    <>
                      <div className="mb-2">
                        <img
                          src={`${apiUrl}/img/${this.state.resulta[1].itens[0].i3}`}
                          style={{
                            width: 250,
                            height: 250,
                            border: "solid 1px",
                            marginRight: 25,
                          }}
                        />
                      </div>
                    </>
                  )}

                  {this.state.resulta[1].itens[0].i4 != null && (
                    <>
                      <div className="mb-2">
                        <img
                          src={`${apiUrl}/img/${this.state.resulta[1].itens[0].i4}`}
                          style={{
                            width: 250,
                            height: 250,
                            border: "solid 1px",
                          }}
                        />
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
          </>
        ) : (
          <>
            <div style={{ border: "solid 1px", marginTop: 15 }}>
              <div style={{ textAlign: "center", background: "#bdd6ee" }}>
                <strong>
                  AÇÕES PROPOSTAS COMO SUGESTÃO DE ADEQUAÇÃO{" "}
                  {process.env.NODE_ENV === "development" && "4"}
                </strong>
              </div>
              <div style={{ margin: 5 }}>Vázio!</div>
            </div>
          </>
        )}

        {this.state.resulta[1].itens.length < 2 && (
          <>
            <RiskAppreciationTable
              code="hrn-4"
              data={this.state.FormHrn}
              resp={(value) => {
                this.setState((prevState) => ({
                  DpMlhs: [...prevState.DpMlhs, value],
                }));
              }}
              title="APRECIAÇÃO DE RISCO DEPOIS DAS AÇÕES DE MELHORIAS "
            />
          </>
        )}
      </div>,
    ];

    var itens3a3 = [
      <>
        <div style={{ marginLeft: 50, marginTop: 10 }}>
          <strong style={{ fontSize: 18 }}>
            12.3 - Dispositivo de partida, acionamento e parada – Geral
          </strong>
        </div>
        <div className="mt-2 mb-2">
          <strong>Situação atual</strong>
        </div>
        {apc2Item3 && apc2Item3.itens.length > 0 && (
          <>
            <div>
              {apc2Item3.itens[0]?.text
                ? apc2Item3.itens[0].text
                    .split("\n")
                    .map((line, index) => <p key={index}>{line}</p>)
                : null}
            </div>
            <div className="d-flex justify-content-center mt-2">
              {apc2Item3.itens[0].img1 != null && (
                <>
                  <div className="mb-2">
                    <img
                      src={`${apiUrl}/img/${apc2Item3.itens[0].img1}`}
                      style={{
                        width: 250,
                        height: 250,
                        border: "solid 1px",
                        marginRight: 25,
                      }}
                    />
                  </div>
                </>
              )}
              {apc2Item3.itens[0].img2 != null && (
                <>
                  <div className="mb-2">
                    <img
                      src={`${apiUrl}/img/${apc2Item3.itens[0].img2}`}
                      style={{
                        width: 250,
                        height: 250,
                        border: "solid 1px",
                      }}
                    />
                  </div>
                </>
              )}
            </div>
            {apc2Item3.itens[0]?.text2
              ? apc2Item3.itens[0].text2
                  .split("\n")
                  .map((line, index) => <p key={index}>{line}</p>)
              : null}
            <div className="d-flex justify-content-center mt-2">
              {apc2Item3.itens[0].img3 != null && (
                <>
                  <div className="mb-2">
                    <img
                      src={`${apiUrl}/img/${apc2Item3.itens[0].img3}`}
                      style={{
                        width: 250,
                        height: 250,
                        border: "solid 1px",
                        marginRight: 25,
                      }}
                    />
                  </div>
                </>
              )}

              {apc2Item3.itens[0].img4 != null && (
                <>
                  <div className="mb-2">
                    <img
                      src={`${apiUrl}/img/${apc2Item3.itens[0].img4}`}
                      style={{
                        width: 250,
                        height: 250,
                        border: "solid 1px",
                      }}
                    />
                  </div>
                </>
              )}
            </div>
          </>
        )}

        {apc2Item3 && apc2Item3.itens.length < 2 && (
          <>
            <RiskAppreciationTable
              code="hrn-5"
              data={this.state.FormHrn}
              resp={(value) => {
                this.setState((prevState) => ({
                  AtMlhs: [...prevState.AtMlhs, value],
                }));
              }}
              title="APRECIAÇÃO DE RISCO ANTES DAS AÇÕES DE MELHORIAS "
            />
          </>
        )}
      </>,
    ];

    var itens3 = [
      <div className="dados">
        {this.state.resulta[2].itens.length > 0 ? (
          <>
            <div style={{ border: "solid 1px", marginTop: 15 }}>
              <div style={{ textAlign: "center", background: "#bdd6ee" }}>
                <strong>
                  AÇÕES PROPOSTAS COMO SUGESTÃO DE ADEQUAÇÃO{" "}
                  {process.env.NODE_ENV === "development" && "5"}
                </strong>
              </div>
              <div style={{ margin: 5 }}>
                {this.state.resulta[2].itens[0].t1 != null && (
                  <div className="mt-2 mb-2">
                    {this.state.resulta[2].itens[0].t1
                      .split("\n")
                      .map((line, index) => (
                        <p key={index}>{line}</p>
                      ))}
                  </div>
                )}

                <div className="d-flex justify-content-center">
                  {this.state.resulta[2].itens[0].i1 != null && (
                    <>
                      <div className="mb-2">
                        <img
                          src={`${apiUrl}/img/${this.state.resulta[2].itens[0].i1}`}
                          style={{
                            width: 225,
                            height: 225,
                            border: "solid 1px",
                            marginRight: 25,
                          }}
                        />
                      </div>
                    </>
                  )}

                  {this.state.resulta[2].itens[0].i2 != null && (
                    <>
                      <div className="mb-2">
                        <img
                          src={`${apiUrl}/img/${this.state.resulta[2].itens[0].i2}`}
                          style={{
                            width: 225,
                            height: 225,
                            border: "solid 1px",
                          }}
                        />
                      </div>
                    </>
                  )}
                </div>
                {this.state.resulta[2].itens[0].t2 != null && (
                  <div className="mt-2 mb-2">
                    {this.state.resulta[2].itens[0].t2
                      .split("\n")
                      .map((line, index) => (
                        <p key={index}>{line}</p>
                      ))}
                  </div>
                )}
                <div className="d-flex justify-content-center mt-3 mb-1">
                  {this.state.resulta[2].itens[0].i3 != null && (
                    <>
                      <div className="mb-2">
                        <img
                          src={`${apiUrl}/img/${this.state.resulta[2].itens[0].i3}`}
                          style={{
                            width: 225,
                            height: 225,
                            border: "solid 1px",
                            marginRight: 25,
                          }}
                        />
                      </div>
                    </>
                  )}

                  {this.state.resulta[2].itens[0].i4 != null && (
                    <>
                      <div className="mb-2">
                        <img
                          src={`${apiUrl}/img/${this.state.resulta[2].itens[0].i4}`}
                          style={{
                            width: 225,
                            height: 225,
                            border: "solid 1px",
                          }}
                        />
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
          </>
        ) : (
          <>
            <div style={{ border: "solid 1px", marginTop: 15 }}>
              <div style={{ textAlign: "center", background: "#bdd6ee" }}>
                <strong>
                  AÇÕES PROPOSTAS COMO SUGESTÃO DE ADEQUAÇÃO{" "}
                  {process.env.NODE_ENV === "development" && "6"}
                </strong>
              </div>
              <div style={{ margin: 5 }}>Vázio!</div>
            </div>
          </>
        )}
        {this.state.resulta[2].itens.length < 2 && (
          <div className="daos">
            <RiskAppreciationTable
              code="hrn-6"
              data={this.state.FormHrn}
              title="APRECIAÇÃO DE RISCO DEPOIS DAS AÇÕES DAS MELHORIAS "
              resp={(value) => {
                this.setState((prevState) => ({
                  DpMlhs: [...prevState.DpMlhs, value],
                }));
              }}
            />
          </div>
        )}
      </div>,
    ];
    var itens3a2 = [
      <>
        <div style={{ marginLeft: 50, marginBottom: 10 }}>
          <strong style={{ fontSize: 18 }}>12.4 - Sistemas de Segurança</strong>
        </div>
        <strong style={{}}>
          <strong>SITUAÇÃO ATUAL</strong>
        </strong>

        {apc2Item4 && apc2Item4.itens.length > 0 && (
          <>
            <div className="mt-2">
              {apc2Item4.itens[0]?.text
                ? apc2Item4.itens[0].text
                    .split("\n")
                    .map((line, index) => <p key={index}>{line}</p>)
                : null}
            </div>
            <div className="d-flex justify-content-center mt-2">
              {apc2Item4.itens[0].img1 != null && (
                <>
                  <div className="mb-2">
                    <img
                      src={`${apiUrl}/img/${apc2Item4.itens[0].img1}`}
                      style={{
                        width: 250,
                        height: 250,
                        border: "solid 1px",
                        marginRight: 25,
                      }}
                    />
                  </div>
                </>
              )}
              {apc2Item4.itens[0].img2 != null && (
                <>
                  <div className="mb-2">
                    <img
                      src={`${apiUrl}/img/${apc2Item4.itens[0].img2}`}
                      style={{
                        width: 250,
                        height: 250,
                        border: "solid 1px",
                      }}
                    />
                  </div>
                </>
              )}
            </div>
            <div>
              {apc2Item4.itens[0]?.text2
                ? apc2Item4.itens[0].text2
                    .split("\n")
                    .map((line, index) => <p key={index}>{line}</p>)
                : null}
            </div>
            <div className="d-flex justify-content-center mt-2">
              {apc2Item4.itens[0].img3 != null && (
                <>
                  <div className="mb-2">
                    <img
                      src={`${apiUrl}/img/${apc2Item4.itens[0].img3}`}
                      style={{
                        width: 250,
                        height: 250,
                        border: "solid 1px",
                        marginRight: 25,
                      }}
                    />
                  </div>
                </>
              )}

              {apc2Item4.itens[0].img4 != null && (
                <>
                  <div className="mb-2">
                    <img
                      src={`${apiUrl}/img/${apc2Item4.itens[0].img4}`}
                      style={{
                        width: 250,
                        height: 250,
                        border: "solid 1px",
                      }}
                    />
                  </div>
                </>
              )}
            </div>
          </>
        )}

        {stc4.length < 1 && (
          <>
            <RiskAppreciationTable
              code="hrn-7"
              data={this.state.FormHrn}
              resp={(value) => {
                this.setState((prevState) => ({
                  AtMlhs: [...prevState.AtMlhs, value],
                }));
              }}
              title="APRECIAÇÃO DE RISCO ANTES DAS AÇÕES DE MELHORIAS "
            />
          </>
        )}
      </>,
    ];
    const gp2 =
      this.state.group_2.length > 0
        ? this.state.group_2.map((item, index) => {
            var e = item.itens;

            return (
              <div style={{ padding: 10 }}>
                {e.txt1 != null && <div className="mb-3">{e.txt1}</div>}

                <div style={{ display: "flex", justifyContent: "center" }}>
                  {e.img1 != null ? (
                    <>
                      <img
                        style={{
                          width: 300,
                          height: 300,
                          border: "solid 2px #000",
                        }}
                        src={`${apiUrl}/img/${e.img1}`}
                      />
                    </>
                  ) : (
                    <></>
                  )}

                  {e.img2 != null ? (
                    <>
                      <img
                        style={{
                          width: 300,
                          height: 300,
                          border: "solid 2px #000",
                          marginLeft: 20,
                        }}
                        src={`${apiUrl}/img/${e.img2}`}
                      />
                    </>
                  ) : (
                    <></>
                  )}
                </div>

                {e.txt2 != null && <div className="mt-3 mb-3">{e.txt2}</div>}

                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    marginTop: 20,
                  }}
                >
                  {e.img3 != null ? (
                    <>
                      <img
                        style={{
                          width: 300,
                          height: 300,
                          border: "solid 2px #000",
                        }}
                        src={`${apiUrl}/img/${e.img3}`}
                      />
                    </>
                  ) : (
                    <div style={{ marginLeft: 20 }}></div>
                  )}

                  {e.img4 != null ? (
                    <>
                      <img
                        style={{
                          width: 300,
                          height: 300,
                          border: "solid 2px #000",
                          marginLeft: 20,
                        }}
                        src={`${apiUrl}/img/${e.img4}`}
                      />
                    </>
                  ) : (
                    <div style={{ marginLeft: 20 }}></div>
                  )}
                </div>
              </div>
            );
          })
        : [];
    var itens4 = [
      <div className="dados">
        {this.state.resulta[3].itens.length > 0 ? (
          <>
            <div style={{ border: "solid 1px", marginTop: 15 }}>
              <div style={{ textAlign: "center", background: "#bdd6ee" }}>
                <strong>
                  AÇÕES PROPOSTAS COMO SUGESTÃO DE ADEQUAÇÃO{" "}
                  {process.env.NODE_ENV === "development" && "7"}
                </strong>
              </div>
              <div style={{ margin: 5 }}>
                {this.state.resulta[3].itens[0].t1 != null && (
                  <div className="mt-2 mb-2">
                    {this.state.resulta[3].itens[0].t1
                      .split("\n")
                      .map((line, index) => (
                        <p key={index}>{line}</p>
                      ))}
                  </div>
                )}

                {this.state.resulta[3].itens[0].t3 != null && (
                  <div className="mt-2 mb-2">
                    {this.state.resulta[3].itens[0].t3
                      .split("\n")
                      .map((line, index) => (
                        <p key={index}>{line}</p>
                      ))}
                  </div>
                )}

                <div className="d-flex justify-content-center">
                  {this.state.resulta[3].itens[0].i1 != null && (
                    <>
                      <div className="mb-2">
                        <img
                          src={`${apiUrl}/img/${this.state.resulta[3].itens[0].i1}`}
                          style={{
                            width: 250,
                            height: 250,
                            marginRight: 25,
                            border: "solid 1px",
                          }}
                        />
                      </div>
                    </>
                  )}

                  {this.state.resulta[3].itens[0].i2 != null && (
                    <>
                      <div className="mb-2">
                        <img
                          src={`${apiUrl}/img/${this.state.resulta[3].itens[0].i2}`}
                          style={{ width: 250, height: 250 }}
                        />
                      </div>
                    </>
                  )}
                </div>

                {this.state.resulta[3].itens[0].t2 != null && (
                  <div className="mt-2 mb-2">
                    {this.state.resulta[3].itens[0].t2
                      .split("\n")
                      .map((line, index) => (
                        <p key={index}>{line}</p>
                      ))}
                  </div>
                )}

                <div className="d-flex justify-content-center mt-3 mb-3">
                  {this.state.resulta[3].itens[0].i3 != null && (
                    <>
                      <div className="mb-2">
                        <img
                          src={`${apiUrl}/img/${this.state.resulta[3].itens[0].i3}`}
                          style={{
                            width: 250,
                            height: 250,
                            marginRight: 25,
                            border: "solid 1px",
                          }}
                        />
                      </div>
                    </>
                  )}

                  {this.state.resulta[3].itens[0].i4 != null && (
                    <>
                      <div className="mb-2">
                        <img
                          src={`${apiUrl}/img/${this.state.resulta[3].itens[0].i4}`}
                          style={{
                            width: 250,
                            height: 250,
                            border: "solid 1px",
                          }}
                        />
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
          </>
        ) : (
          <>
            <div style={{ border: "solid 1px", marginTop: 15 }}>
              <div style={{ textAlign: "center", background: "#bdd6ee" }}>
                <strong>
                  AÇÕES PROPOSTAS COMO SUGESTÃO DE ADEQUAÇÃO{" "}
                  {process.env.NODE_ENV === "development" && "8"}
                </strong>
              </div>
              <div style={{ margin: 5 }}>Vázio!</div>
            </div>
          </>
        )}
        {this.state.resulta[3].itens.length < 2 && (
          <>
            <RiskAppreciationTable
              code="hrn-8"
              data={this.state.FormHrn}
              resp={(value) => {
                this.setState((prevState) => ({
                  DpMlhs: [...prevState.DpMlhs, value],
                }));
              }}
              title="APRECIAÇÃO DE RISCO DEPOIS DAS AÇÕES DE MELHORIAS"
            />
          </>
        )}
      </div>,
    ];

    var itens4b = [
      <>
        <div style={{ marginLeft: 50, marginTop: 10 }}>
          <strong style={{ fontSize: 18 }}>
            12.5 - Dispositivo de parada de emergência – Geral
          </strong>
        </div>
        <div className="mt-2 mb-2">
          <strong>Situação atual</strong>
        </div>
        {apc2Item5 && apc2Item5.itens.length > 0 && (
          <div className="dados">
            <div>
              {apc2Item5.itens[0]?.text
                ? apc2Item5.itens[0].text
                    .split("\n")
                    .map((line, index) => <p key={index}>{line}</p>)
                : null}
            </div>
            <div className="d-flex justify-content-center mt-2">
              {apc2Item5.itens[0].img1 != null && (
                <>
                  <div className="mb-2">
                    <img
                      src={`${apiUrl}/img/${apc2Item5.itens[0].img1}`}
                      style={{
                        width: 250,
                        height: 250,
                        border: "solid 1px",
                        marginRight: 25,
                      }}
                    />
                  </div>
                </>
              )}
              {apc2Item5.itens[0].img2 != null && (
                <>
                  <div className="mb-2">
                    <img
                      src={`${apiUrl}/img/${apc2Item5.itens[0].img2}`}
                      style={{
                        width: 250,
                        height: 250,
                        border: "solid 1px",
                      }}
                    />
                  </div>
                </>
              )}
            </div>
            <div className="d-flex justify-content-center mt-2">
              {apc2Item5.itens[0].img3 != null && (
                <>
                  <div className="mb-2">
                    <img
                      src={`${apiUrl}/img/${apc2Item5.itens[0].img3}`}
                      style={{
                        width: 250,
                        height: 250,
                        border: "solid 1px",
                        marginRight: 25,
                      }}
                    />
                  </div>
                </>
              )}

              {apc2Item5.itens[0].img4 != null && (
                <>
                  <div className="mb-2">
                    <img
                      src={`${apiUrl}/img/${apc2Item5.itens[0].img4}`}
                      style={{
                        width: 250,
                        height: 250,
                        border: "solid 1px",
                      }}
                    />
                  </div>
                </>
              )}
            </div>
          </div>
        )}
        {apc2Item5.itens.length <= 1 && (
          <div className="dados">
            <RiskAppreciationTable
              code="hrn-9"
              data={this.state.FormHrn}
              resp={(value) => {
                this.setState((prevState) => ({
                  AtMlhs: [...prevState.AtMlhs, value],
                }));
              }}
              title="APRECIAÇÃO DE RISCO ANTES DAS AÇÕES DE MELHORIAS "
            />
          </div>
        )}
      </>,
    ];

    var itens4c = [
      <div className="dados">
        <div style={{ marginLeft: 50, marginTop: 10 }}>
          <strong style={{ fontSize: 18 }}>
            12.6 - Manutenção, inspeção, preparação, ajuste, reparo e limpeza.
          </strong>
        </div>

        <div style={{ marginTop: 10 }}>
          <p>
            12.11.3 A manutenção, inspeção, reparos, limpeza, ajuste e outras
            intervenções que se fizerem necessárias devem ser executadas por
            profissionais capacitados, qualificados ou legalmente habilitados,
            formalmente autorizados pelo empregador, com as máquinas e
            equipamentos parados e adoção dos seguintes procedimentos:
          </p>
        </div>

        <div>
          <p>
            a) Isolamento e descarga de todas as fontes de energia das máquinas
            e equipamentos, de modo visível ou facilmente identificável por meio
            dos dispositivos de comando;
          </p>
        </div>

        <p>
          b) Bloqueio mecânico e elétrico na posição “desligado” ou “fechado” de
          todos os dispositivos de corte de fontes de energia, a fim de impedir
          a energização, e sinalização com cartão ou etiqueta de bloqueio
          contendo o horário e a data do bloqueio, o motivo da manutenção e o
          nome do responsável;
        </p>

        <p>
          c) Medidas que garantam que à jusante dos pontos de corte de energia
          não exista possibilidade de gerar risco de acidentes;
        </p>

        <p>
          d) Medidas adicionais de segurança, quando for realizada manutenção,
          inspeção e reparos de máquinas ou equipamentos sustentados somente por
          sistemas hidráulicos e pneumáticos;
        </p>

        <p>
          e) Sistemas de retenção com trava mecânica, para evitar o movimento de
          retorno.
        </p>
        <div className="mb-3">
          <strong>Situação atual</strong>
        </div>
        {apc2Item6 && apc2Item6.itens.length > 0 && (
          <>
            <div>
              {apc2Item6.itens[0]?.text
                ? apc2Item6.itens[0].text
                    .split("\n")
                    .map((line, index) => <p key={index}>{line}</p>)
                : null}
            </div>
            <div className="d-flex justify-content-center mt-2 mb-2">
              {apc2Item6.itens[0].img1 != null && (
                <>
                  <div className="mb-2">
                    <img
                      src={`${apiUrl}/img/${apc2Item6.itens[0].img1}`}
                      style={{
                        width: 250,
                        height: 250,
                        border: "solid 1px",
                        marginRight: 25,
                      }}
                    />
                  </div>
                </>
              )}
              {apc2Item6.itens[0].img2 != null && (
                <>
                  <div className="mb-2">
                    <img
                      src={`${apiUrl}/img/${apc2Item6.itens[0].img2}`}
                      style={{
                        width: 250,
                        height: 250,
                        border: "solid 1px",
                      }}
                    />
                  </div>
                </>
              )}
            </div>
            <div>
              {apc2Item6.itens[0]?.text2
                ? apc2Item6.itens[0].text2
                    .split("\n")
                    .map((line, index) => <p key={index}>{line}</p>)
                : null}
            </div>
            <div className="d-flex justify-content-center mt-2">
              {apc2Item6.itens[0].img3 != null && (
                <>
                  <div className="mb-2">
                    <img
                      src={`${apiUrl}/img/${apc2Item6.itens[0].img3}`}
                      style={{
                        width: 250,
                        height: 250,
                        border: "solid 1px",
                        marginRight: 25,
                      }}
                    />
                  </div>
                </>
              )}

              {apc2Item6.itens[0].img4 != null && (
                <>
                  <div className="mb-2">
                    <img
                      src={`${apiUrl}/img/${apc2Item6.itens[0].img4}`}
                      style={{
                        width: 250,
                        height: 250,
                        border: "solid 1px",
                      }}
                    />
                  </div>
                </>
              )}
            </div>
          </>
        )}

        {apc2Item6.itens.length < 2 && (
          <RiskAppreciationTable
            code="hrn-11"
            data={this.state.FormHrn}
            resp={(value) => {
              this.setState((prevState) => ({
                AtMlhs: [...prevState.AtMlhs, value],
              }));
            }}
            title="APRECIAÇÃO DE RISCO ANTES DAS AÇÕES DE MELHORIAS "
          />
        )}
      </div>,
    ];

    var adc4 = [
      <>
        {this.state.resulta[4].itens.length > 0 ? (
          <>
            <div style={{ border: "solid 1px", marginTop: 15 }}>
              <div style={{ textAlign: "center", background: "#bdd6ee" }}>
                <strong>
                  AÇÕES PROPOSTAS COMO SUGESTÃO DE ADEQUAÇÃO{" "}
                  {process.env.NODE_ENV === "development" && "9"}
                </strong>
              </div>
              <div style={{ margin: 5 }}>
                {this.state.resulta[4].itens[0].t1 != null && (
                  <div className="mt-2 mb-2">
                    {this.state.resulta[4].itens[0].t1
                      .split("\n")
                      .map((line, index) => (
                        <p key={index}>{line}</p>
                      ))}
                  </div>
                )}

                <div className="d-flex justify-content-center">
                  {this.state.resulta[4].itens[0].i1 != null && (
                    <>
                      <div className="mb-2">
                        <img
                          src={`${apiUrl}/img/${this.state.resulta[4].itens[0].i1}`}
                          style={{
                            width: 225,
                            height: 225,
                            marginRight: 25,
                            border: "solid 1px",
                          }}
                        />
                      </div>
                    </>
                  )}

                  {this.state.resulta[4].itens[0].i2 != null && (
                    <>
                      <div className="mb-2">
                        <img
                          src={`${apiUrl}/img/${this.state.resulta[4].itens[0].i2}`}
                          style={{
                            width: 225,
                            height: 225,
                            border: "solid 1px",
                          }}
                        />
                      </div>
                    </>
                  )}
                </div>
                {this.state.resulta[4].itens[0].t2 != null && (
                  <div className="mt-2 mb-2">
                    {this.state.resulta[4].itens[0].t2
                      .split("\n")
                      .map((line, index) => (
                        <p key={index}>{line}</p>
                      ))}
                  </div>
                )}

                <div className="d-flex justify-content-center mt-2 mb-2">
                  {this.state.resulta[4].itens[0].i3 != null && (
                    <>
                      <div className="mb-2">
                        <img
                          src={`${apiUrl}/img/${this.state.resulta[4].itens[0].i3}`}
                          style={{
                            width: 225,
                            height: 225,
                            marginRight: 25,
                            border: "solid 1px",
                          }}
                        />
                      </div>
                    </>
                  )}

                  {this.state.resulta[4].itens[0].i4 != null && (
                    <>
                      <div className="mb-2">
                        <img
                          src={`${apiUrl}/img/${this.state.resulta[4].itens[0].i4}`}
                          style={{
                            width: 225,
                            height: 225,
                            border: "solid 1px",
                          }}
                        />
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
          </>
        ) : (
          <>
            <div style={{ border: "solid 1px", marginTop: 15 }}>
              <div style={{ textAlign: "center", background: "#bdd6ee" }}>
                <strong>
                  AÇÕES PROPOSTAS COMO SUGESTÃO DE ADEQUAÇÃO{" "}
                  {process.env.NODE_ENV === "development" && "10"}
                </strong>
              </div>
              <div style={{ margin: 5 }}>Vázio!</div>
            </div>
          </>
        )}

        <RiskAppreciationTable
          code="hrn-10"
          data={this.state.FormHrn}
          resp={(value) => {
            this.setState((prevState) => ({
              DpMlhs: [...prevState.DpMlhs, value],
            }));
          }}
          title="APRECIAÇÃO DE RISCO DEPOIS DAS AÇÕES DE MELHORIAS "
        />
      </>,
    ];
    var itens4d = [
      <div className="dados">
        {this.state.resulta[5].itens.length > 0 ? (
          <>
            <div style={{ border: "solid 1px", marginTop: 15 }}>
              <div style={{ textAlign: "center", background: "#bdd6ee" }}>
                <strong>
                  AÇÕES PROPOSTAS COMO SUGESTÃO DE ADEQUAÇÃO{" "}
                  {process.env.NODE_ENV === "development" && "11"}
                </strong>
              </div>
              <div style={{ margin: 5 }}>
                {this.state.resulta[5].itens[0].t1 != null && (
                  <div className="mt-2 mb-2">
                    {this.state.resulta[5].itens[0].t1 != null && (
                      <div className="mt-2 mb-2">
                        {this.state.resulta[5].itens[0].t1
                          .split("\n")
                          .map((line, index) => (
                            <p key={index}>{line}</p>
                          ))}
                      </div>
                    )}
                  </div>
                )}
                <div className="d-flex justify-content-center">
                  {this.state.resulta[5].itens[0].i1 != null && (
                    <>
                      <div className="mb-2">
                        <img
                          src={`${apiUrl}/img/${this.state.resulta[5].itens[0].i1}`}
                          style={{
                            width: 200,
                            height: 200,
                            marginRight: 10,
                            border: "solid 1px",
                          }}
                        />
                      </div>
                    </>
                  )}

                  {this.state.resulta[5].itens[0].i2 != null && (
                    <>
                      <div className="mb-2">
                        <img
                          src={`${apiUrl}/img/${this.state.resulta[5].itens[0].i2}`}
                          style={{
                            width: 200,
                            height: 200,
                            marginRight: 10,
                            border: "solid 1px",
                          }}
                        />
                      </div>
                    </>
                  )}
                </div>

                <div className="d-flex justify-content-center">
                  {this.state.resulta[5].itens[0].i3 != null && (
                    <>
                      <div className="mb-2">
                        <img
                          src={`${apiUrl}/img/${this.state.resulta[5].itens[0].i3}`}
                          style={{
                            width: 200,
                            height: 200,
                            marginRight: 10,
                            border: "solid 1px",
                          }}
                        />
                      </div>
                    </>
                  )}

                  {this.state.resulta[5].itens[0].i4 != null && (
                    <>
                      <div className="mb-2">
                        <img
                          src={`${apiUrl}/img/${this.state.resulta[5].itens[0].i4}`}
                          style={{
                            width: 200,
                            height: 200,
                            marginRight: 10,
                            border: "solid 1px",
                          }}
                        />
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
          </>
        ) : (
          <>
            <div style={{ border: "solid 1px", marginTop: 15 }}>
              <div style={{ textAlign: "center", background: "#bdd6ee" }}>
                <strong>
                  AÇÕES PROPOSTAS COMO SUGESTÃO DE ADEQUAÇÃO{" "}
                  {process.env.NODE_ENV === "development" && "12"}
                </strong>
              </div>
              <div style={{ margin: 5 }}>Vázio!</div>
            </div>
          </>
        )}

        {this.state.resulta[5].itens.length < 2 && (
          <>
            <RiskAppreciationTable
              code="hrn-12"
              data={this.state.FormHrn}
              resp={(value) => {
                this.setState((prevState) => ({
                  DpMlhs: [...prevState.DpMlhs, value],
                }));
              }}
              title="APRECIAÇÃO DE RISCO DEPOIS DAS AÇÕES DE MELHORIAS "
            />
          </>
        )}
      </div>,
    ];
    var itens4daa = [
      <>
        <div style={{ marginLeft: 50, marginTop: 10 }}>
          <strong className="mt-3 mb-3" style={{ fontSize: 18 }}>
            12.7 - Sinalização, manuais, procedimentos e capacitação
          </strong>
        </div>
        <p></p>
        <div>
          <strong>Situação atual</strong>
        </div>
        {apc2Item7 && apc2Item7.itens.length > 0 && (
          <>
            <div>
              {apc2Item7.itens[0]?.text
                ? apc2Item7.itens[0].text
                    .split("\n")
                    .map((line, index) => <p key={index}>{line}</p>)
                : null}
            </div>
            <div className="d-flex justify-content-center mt-2 mb-2">
              {apc2Item7.itens[0].img1 != null && (
                <>
                  <div className="mb-2">
                    <img
                      src={`${apiUrl}/img/${apc2Item7.itens[0].img1}`}
                      style={{
                        width: 200,
                        height: 200,
                        border: "solid 1px",
                        marginRight: 25,
                      }}
                    />
                  </div>
                </>
              )}
              {apc2Item7.itens[0].img2 != null && (
                <>
                  <div className="mb-2">
                    <img
                      src={`${apiUrl}/img/${apc2Item7.itens[0].img2}`}
                      style={{
                        width: 200,
                        height: 200,
                        border: "solid 1px",
                      }}
                    />
                  </div>
                </>
              )}
            </div>
            <div>
              {apc2Item7.itens[0]?.text2
                ? apc2Item7.itens[0].text2
                    .split("\n")
                    .map((line, index) => <p key={index}>{line}</p>)
                : null}{" "}
            </div>
            <div className="d-flex justify-content-center mt-2">
              {apc2Item7.itens[0].img3 != null && (
                <>
                  <div className="mb-2">
                    <img
                      src={`${apiUrl}/img/${apc2Item7.itens[0].img3}`}
                      style={{
                        width: 200,
                        height: 200,
                        border: "solid 1px",
                        marginRight: 25,
                      }}
                    />
                  </div>
                </>
              )}

              {apc2Item7.itens[0].img4 != null && (
                <>
                  <div className="mb-2">
                    <img
                      src={`${apiUrl}/img/${apc2Item7.itens[0].img4}`}
                      style={{
                        width: 200,
                        height: 200,
                        border: "solid 1px",
                      }}
                    />
                  </div>
                </>
              )}
            </div>
          </>
        )}

        {apc2Item7.itens.length < 2 && (
          <RiskAppreciationTable
            code="hrn-13"
            data={this.state.FormHrn}
            resp={(value) => {
              this.setState((prevState) => ({
                AtMlhs: [...prevState.AtMlhs, value],
              }));
            }}
            title="APRECIAÇÃO DE RISCO ANTES DAS AÇÕES DE MELHORIAS "
          />
        )}
      </>,
    ];
    var itens4da = [
      <div className="dados">
        {this.state.resulta[6].itens.length > 0 ? (
          <>
            <div style={{ border: "solid 1px", marginTop: 15 }}>
              <div style={{ textAlign: "center", background: "#bdd6ee" }}>
                <strong>
                  AÇÕES PROPOSTAS COMO SUGESTÃO DE ADEQUAÇÃO{" "}
                  {process.env.NODE_ENV === "development" && "13"}
                </strong>
              </div>
              <div style={{ margin: 5 }}>
                {this.state.resulta[6].itens[0].t1 != null && (
                  <div className="mt-2 mb-2">
                    {this.state.resulta[6].itens[0].t1
                      .split("\n")
                      .map((line, index) => (
                        <p key={index}>{line}</p>
                      ))}
                  </div>
                )}

                {this.state.resulta[6].itens[0].t3 != null && (
                  <div className="mt-2 mb-2">
                    {this.state.resulta[6].itens[0].t3
                      .split("\n")
                      .map((line, index) => (
                        <p key={index}>{line}</p>
                      ))}
                  </div>
                )}

                <div className="d-flex justify-content-center">
                  {this.state.resulta[6].itens[0].i1 != null && (
                    <>
                      <div className="mb-2">
                        <img
                          src={`${apiUrl}/img/${this.state.resulta[6].itens[0].i1}`}
                          style={{
                            width: 250,
                            height: 250,
                            marginRight: 10,
                            border: "solid 1px",
                          }}
                        />
                      </div>
                    </>
                  )}

                  {this.state.resulta[6].itens[0].i2 != null && (
                    <>
                      <div className="mb-2">
                        <img
                          src={`${apiUrl}/img/${this.state.resulta[6].itens[0].i2}`}
                          style={{
                            width: 250,
                            height: 250,
                            border: "solid 1px",
                          }}
                        />
                      </div>
                    </>
                  )}
                </div>

                <div className="d-flex justify-content-center">
                  {this.state.resulta[6].itens[0].i3 != null && (
                    <>
                      <div className="mb-2">
                        <img
                          src={`${apiUrl}/img/${this.state.resulta[6].itens[0].i3}`}
                          style={{
                            width: 250,
                            height: 250,
                            marginRight: 10,
                            border: "solid 1px",
                          }}
                        />
                      </div>
                    </>
                  )}
                  {this.state.resulta[6].itens[0].i4 != null && (
                    <>
                      <div className="mb-2">
                        <img
                          src={`${apiUrl}/img/${this.state.resulta[6].itens[0].i4}`}
                          style={{
                            width: 250,
                            height: 250,
                            border: "solid 1px",
                          }}
                        />
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
          </>
        ) : (
          <>
            <div style={{ border: "solid 1px", marginTop: 15 }}>
              <div style={{ textAlign: "center", background: "#bdd6ee" }}>
                <strong>AÇÕES PROPOSTAS COMO SUGESTÃO DE ADEQUAÇÃO</strong>
              </div>
              <div style={{ margin: 5 }}>Vázio!</div>
            </div>
          </>
        )}

        {this.state.resulta[6].itens.length < 2 && (
          <>
            <RiskAppreciationTable
              code="hrn-14"
              data={this.state.FormHrn}
              resp={(value) => {
                this.setState((prevState) => ({
                  DpMlhs: [...prevState.DpMlhs, value],
                }));
              }}
              title="APRECIAÇÃO DE RISCO DEPOIS DAS AÇÕES DE MELHORIAS "
            />
          </>
        )}
      </div>,
    ];

    var gp = this.state.GroupList;
    var result = [];

    for (let i = 1; i <= 7; i++) {
      let code = `12.${i}`;
      let item = gp.find((item) => item.code === code);

      if (code != "12.2" && code != "12.4") {
        if (item !== undefined) {
          result.push(item);
        } else {
          result.push({
            code,
            itens: [],
          });
        }
      }
    }

    const rst0 =
      result[0].itens.length > 0
        ? result[0].itens.map((item, index) => (
            <>
              <div style={{ padding: 10 }}>
                -ola
                {index == 0 && (
                  <>
                    {/* <div style={{marginBottom:20}}><strong>SITUAÇÃO ATUAL:</strong></div>*/}
                  </>
                )}
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    gap: "10px",
                  }}
                >
                  {item.img1 != null && (
                    <>
                      <img
                        src={`${apiUrl}/img/${item.img1}`}
                        style={{ width: 300, height: 300, border: "solid 1px" }}
                      />
                    </>
                  )}
                  {item.img2 != null && (
                    <>
                      <img
                        src={`${apiUrl}/img/${item.img2}`}
                        style={{ width: 300, height: 300, border: "solid 1px" }}
                      />
                    </>
                  )}
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    marginTop: 10,
                    gap: "10px",
                  }}
                >
                  {item.img3 != null && (
                    <>
                      <img
                        src={`${apiUrl}/img/${item.img3}`}
                        style={{ width: 300, height: 300, border: "solid 1px" }}
                      />
                    </>
                  )}
                  {item.img3 != null && (
                    <>
                      <img
                        src={`${apiUrl}/img/${item.img4}`}
                        style={{ width: 300, height: 300, border: "solid 1px" }}
                      />
                    </>
                  )}
                </div>
              </div>
            </>
          ))
        : [];

    const gp1 =
      this.state.data.groupi.length > 0
        ? this.state.data.groupi.map((item, index) => (
            <>
              <div style={{ padding: 10 }}>
                {index == 0 && (
                  <>
                    <TextView
                      vz="v"
                      type="text"
                      code="2354r65fdh"
                      data={this.state.TextView}
                    />

                    <div>
                      {/*    <strong>
    SITUAÇÃO ATUAL: 
    </strong> */}
                    </div>
                    <div>
                      {this.state.groupi[0] != undefined && (
                        <>
                          <div className="container d-flex justify-content-center">
                            <div className="row">
                              {this.state.groupi[0].list
                                .slice(0, 4)
                                .map((item, index) => (
                                  <div
                                    key={index}
                                    className="col-6 mb-5 d-flex justify-content-center"
                                  >
                                    <div className="image-item">
                                      <img
                                        src={`${apiUrl}/img/${item.img}`}
                                        className="img-fluid"
                                        alt={`Item ${index}`}
                                      />
                                    </div>
                                  </div>
                                ))}
                            </div>
                          </div>
                        </>
                      )}
                    </div>
                  </>
                )}
              </div>
            </>
          ))
        : [];

    const resulta1 =
      this.state.resulta[1].itens.length > 0
        ? this.state.resulta[1].itens.slice(1).map((item, index) => (
            <>
              <div style={{ padding: 10 }}>
                <div style={{ border: "solid 1px", marginTop: 15 }}>
                  <div style={{ margin: 5 }}>
                    {item.t1 != null && (
                      <>
                        <p>{item.t1}</p>
                      </>
                    )}

                    {item.i1 != null && (
                      <>
                        <div className="mb-2">
                          <img
                            src={`${apiUrl}/img/${item.i1}`}
                            style={{ width: 250, height: 250 }}
                          />
                        </div>
                      </>
                    )}

                    {item.t2 != null && (
                      <>
                        <p>{item.t2}</p>
                      </>
                    )}

                    {item.i2 != null && (
                      <>
                        <div className="mb-2">
                          <img
                            src={`${apiUrl}/img/${item.i2}`}
                            style={{ width: 250, height: 250 }}
                          />
                        </div>
                      </>
                    )}

                    {item.t3 != null &&
                      item.t3
                        .split("\n")
                        .map((line, index) => <p key={index}>{line}</p>)}

                    {item.i3 != null && (
                      <>
                        <div className="mb-2">
                          <img
                            src={`${apiUrl}/img/${item.i3}`}
                            style={{ width: 250, height: 250 }}
                          />
                        </div>
                      </>
                    )}
                  </div>
                </div>

                {index == this.state.resulta[1].itens.length - 2 && (
                  <>
                    <RiskAppreciationTable
                      code="hrn-4"
                      data={this.state.FormHrn}
                      resp={(value) => {
                        this.setState((prevState) => ({
                          DpMlhs: [...prevState.DpMlhs, value],
                        }));
                      }}
                      title="APRECIAÇÃO DE RISCO DEPOIS DAS AÇÕES DE MELHORIAS "
                    />
                  </>
                )}
              </div>
            </>
          ))
        : [];

    const rst1 =
      result[1].itens.length > 0
        ? result[1].itens.map((item, index) => (
            <>
              <div style={{ padding: 10 }}>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    gap: "10px",
                  }}
                >
                  {item.img1 != null && (
                    <>
                      <img
                        src={`${apiUrl}/img/${item.img1}`}
                        style={{ width: 300, height: 300, border: "solid 1px" }}
                      />
                    </>
                  )}
                  {item.img2 != null && (
                    <>
                      <img
                        src={`${apiUrl}/img/${item.img2}`}
                        style={{ width: 300, height: 300, border: "solid 1px" }}
                      />
                    </>
                  )}
                </div>

                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    marginTop: 10,
                    gap: "10px",
                  }}
                >
                  {item.img3 != null && (
                    <>
                      <img
                        src={`${apiUrl}/img/${item.img3}`}
                        style={{ width: 300, height: 300, border: "solid 1px" }}
                      />
                    </>
                  )}

                  {item.img4 != null && (
                    <>
                      <img
                        src={`${apiUrl}/img/${item.img4}`}
                        style={{ width: 300, height: 300, border: "solid 1px" }}
                      />
                    </>
                  )}
                </div>
              </div>
            </>
          ))
        : [];

    const resulta2 =
      this.state.resulta[2].itens.length > 0
        ? this.state.resulta[2].itens.slice(1).map((item, index) => (
            <div className="dados">
              <div style={{ padding: 10 }}>
                <div style={{ border: "solid 1px", marginTop: 15 }}>
                  <div style={{ margin: 5 }}>
                    {item.t1 != null && (
                      <>
                        <p>{item.t1}</p>
                      </>
                    )}

                    {item.i1 != null && (
                      <>
                        <div className="mb-2">
                          <img
                            src={`${apiUrl}/img/${item.i1}`}
                            style={{ width: 250, height: 250 }}
                          />
                        </div>
                      </>
                    )}

                    {item.t2 != null && (
                      <>
                        <p>{item.t2}</p>
                      </>
                    )}

                    {item.i2 != null && (
                      <>
                        <div className="mb-2">
                          <img
                            src={`${apiUrl}/img/${item.i2}`}
                            style={{ width: 250, height: 250 }}
                          />
                        </div>
                      </>
                    )}

                    {item.t3 != null &&
                      item.t3
                        .split("\n")
                        .map((line, index) => <p key={index}>{line}</p>)}

                    {item.i3 != null && (
                      <>
                        <div className="mb-2">
                          <img
                            src={`${apiUrl}/img/${item.i3}`}
                            style={{ width: 250, height: 250 }}
                          />
                        </div>
                      </>
                    )}
                  </div>
                </div>

                {index === this.state.resulta[2].itens.length - 2 && (
                  <RiskAppreciationTable
                    code="hrn-6"
                    data={this.state.FormHrn}
                    title="APRECIAÇÃO DE RISCO DEPOIS DAS AÇÕES DAS MELHORIAS"
                    resp={(value) => {
                      this.setState((prevState) => ({
                        DpMlhs: [...prevState.DpMlhs, value],
                      }));
                    }}
                  />
                )}
              </div>
            </div>
          ))
        : [];

    const resulta3 =
      this.state.resulta[3].itens.length > 0
        ? this.state.resulta[3].itens.slice(1).map((item, index) => (
            <>
              <div style={{ padding: 10 }}>
                <div style={{ border: "solid 1px", marginTop: 15 }}>
                  <div style={{ margin: 5 }}>
                    {item.t3 != null &&
                      item.t3
                        .split("\n")
                        .map((line, index) => <p key={index}>{line}</p>)}
                    {item.t1 != null && (
                      <div className="mb-2 mt-2">
                        {item?.t1
                          ? item.t1
                              .split("\n")
                              .map((line, index) => <p key={index}>{line}</p>)
                          : null}
                      </div>
                    )}
                    <div className="d-flex justify-content-center">
                      {item.i1 != null && (
                        <>
                          <div className="mb-2">
                            <img
                              src={`${apiUrl}/img/${item.i1}`}
                              style={{
                                width: 250,
                                height: 250,
                                border: "solid 1px",
                                marginRight: 25,
                              }}
                            />
                          </div>
                        </>
                      )}

                      {item.i2 != null && (
                        <>
                          <div className="mb-2">
                            <img
                              src={`${apiUrl}/img/${item.i2}`}
                              style={{
                                width: 250,
                                height: 250,
                                border: "solid 1px",
                              }}
                            />
                          </div>
                        </>
                      )}
                    </div>
                    {item.t2 != null && (
                      <div className="mb-2 mt-2">
                        {item?.t2
                          ? item.t2
                              .split("\n")
                              .map((line, index) => <p key={index}>{line}</p>)
                          : null}
                      </div>
                    )}
                    <div className="d-flex justify-content-center mt-3 mb-3">
                      {item.i3 != null && (
                        <>
                          <div className="mb-2">
                            <img
                              src={`${apiUrl}/img/${item.i3}`}
                              style={{
                                width: 250,
                                height: 250,
                                border: "solid 1px",
                                marginRight: 25,
                              }}
                            />
                          </div>
                        </>
                      )}

                      {item.i4 != null && (
                        <>
                          <div className="mb-2">
                            <img
                              src={`${apiUrl}/img/${item.i4}`}
                              style={{
                                width: 250,
                                height: 250,
                                border: "solid 1px",
                              }}
                            />
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                </div>

                {index === this.state.resulta[3].itens.length - 2 && (
                  <RiskAppreciationTable
                    code="hrn-8"
                    data={this.state.FormHrn}
                    resp={(value) => {
                      this.setState((prevState) => ({
                        DpMlhs: [...prevState.DpMlhs, value],
                      }));
                    }}
                    title="APRECIAÇÃO DE RISCO DEPOIS DAS AÇÕES DE MELHORIAS "
                  />
                )}
              </div>
            </>
          ))
        : [];

    const resulta4 =
      this.state.resulta[4].itens.length > 0
        ? this.state.resulta[4].itens.slice(1).map((item, index) => (
            <>
              <div style={{ padding: 10 }}>
                <div style={{ border: "solid 1px", marginTop: 15 }}>
                  <div style={{ margin: 5 }}>
                    {item.t3 != null &&
                      item.t3
                        .split("\n")
                        .map((line, index) => <p key={index}>{line}</p>)}

                    {item.t1 != null && (
                      <>
                        <p>{item.t1}</p>
                      </>
                    )}

                    <div className="d-flex justify-content-center">
                      {item.i1 != null && (
                        <>
                          <div className="mb-2">
                            <img
                              src={`${apiUrl}/img/${item.i1}`}
                              style={{
                                width: 250,
                                height: 250,
                                border: "solid 1px",
                                marginRight: 25,
                              }}
                            />
                          </div>
                        </>
                      )}

                      {item.i2 != null && (
                        <>
                          <div className="mb-2">
                            <img
                              src={`${apiUrl}/img/${item.i2}`}
                              style={{
                                width: 250,
                                height: 250,
                                border: "solid 1px",
                              }}
                            />
                          </div>
                        </>
                      )}
                    </div>
                    <div className="d-flex justify-content-center mt-3 mb-3">
                      {item.i3 != null && (
                        <>
                          <div className="mb-2">
                            <img
                              src={`${apiUrl}/img/${item.i3}`}
                              style={{
                                width: 250,
                                height: 250,
                                border: "solid 1px",
                                marginRight: 25,
                              }}
                            />
                          </div>
                        </>
                      )}

                      {item.i4 != null && (
                        <>
                          <div className="mb-2">
                            <img
                              src={`${apiUrl}/img/${item.i4}`}
                              style={{
                                width: 250,
                                height: 250,
                                border: "solid 1px",
                              }}
                            />
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                </div>

                {index === this.state.resulta[4].itens.length - 2 && (
                  <>
                    <RiskAppreciationTable
                      code="hrn-11"
                      data={this.state.FormHrn}
                      resp={(value) => {
                        this.setState((prevState) => ({
                          DpMlhs: [...prevState.DpMlhs, value],
                        }));
                      }}
                      title="APRECIAÇÃO DE RISCO DEPOIS DAS AÇÕES DE MELHORIAS "
                    />
                  </>
                )}
              </div>
            </>
          ))
        : [];

    const rst2 =
      result[2].itens.length > 0
        ? result[2].itens.map((item, index) => (
            <>
              <div style={{ padding: 10 }}>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    gap: "10px",
                  }}
                >
                  {item.img1 != null && (
                    <>
                      <img
                        src={`${apiUrl}/img/${item.img1}`}
                        style={{ width: 300, height: 300, border: "solid 1px" }}
                      />
                    </>
                  )}
                  {item.img2 != null && (
                    <>
                      <img
                        src={`${apiUrl}/img/${item.img2}`}
                        style={{ width: 300, height: 300, border: "solid 1px" }}
                      />
                    </>
                  )}
                </div>

                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    marginTop: 10,
                    gap: "10px",
                  }}
                >
                  {item.img3 != null && (
                    <>
                      <img
                        src={`${apiUrl}/img/${item.img3}`}
                        style={{ width: 300, height: 300, border: "solid 1px" }}
                      />
                    </>
                  )}
                  {item.img3 != null && (
                    <>
                      <img
                        src={`${apiUrl}/img/${item.img4}`}
                        style={{ width: 300, height: 300, border: "solid 1px" }}
                      />
                    </>
                  )}
                </div>
              </div>
            </>
          ))
        : [];

    const rst3 =
      result[3].itens.length > 0
        ? result[3].itens.map((item, index) => (
            <>
              <div style={{ padding: 10 }}>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    gap: "10px",
                  }}
                >
                  {item.img1 != null && (
                    <>
                      <img
                        src={`${apiUrl}/img/${item.img1}`}
                        style={{ width: 300, height: 300, border: "solid 1px" }}
                      />
                    </>
                  )}
                  {item.img2 != null && (
                    <>
                      <img
                        src={`${apiUrl}/img/${item.img2}`}
                        style={{ width: 300, height: 300, border: "solid 1px" }}
                      />
                    </>
                  )}
                </div>

                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    marginTop: 10,
                    gap: "10px",
                  }}
                >
                  {item.img3 != null && (
                    <>
                      <img
                        src={`${apiUrl}/img/${item.img3}`}
                        style={{ width: 300, height: 300, border: "solid 1px" }}
                      />
                    </>
                  )}
                  {item.img3 != null && (
                    <>
                      <img
                        src={`${apiUrl}/img/${item.img4}`}
                        style={{ width: 300, height: 300, border: "solid 1px" }}
                      />
                    </>
                  )}
                </div>
              </div>
            </>
          ))
        : [];

    const resulta5 =
      this.state.resulta[5].itens.length > 0
        ? this.state.resulta[5].itens.slice(1).map((item, index) => (
            <>
              <div style={{ padding: 10 }}>
                <div style={{ border: "solid 1px", marginTop: 15 }}>
                  <div style={{ margin: 5 }}>
                    {item.t3 != null &&
                      item.t3
                        .split("\n")
                        .map((line, index) => <p key={index}>{line}</p>)}

                    {item.t1 != null && (
                      <>
                        <p>{item.t1}</p>
                      </>
                    )}

                    {item.i1 != null && (
                      <>
                        <div className="mb-2">
                          <img
                            src={`${apiUrl}/img/${item.i1}`}
                            style={{ width: 250, height: 250 }}
                          />
                        </div>
                      </>
                    )}

                    {item.t2 != null && (
                      <>
                        <p>{item.t2}</p>
                      </>
                    )}

                    {item.i2 != null && (
                      <>
                        <div className="mb-2">
                          <img
                            src={`${apiUrl}/img/${item.i2}`}
                            style={{ width: 250, height: 250 }}
                          />
                        </div>
                      </>
                    )}

                    {item.t3 != null &&
                      item.t3
                        .split("\n")
                        .map((line, index) => <p key={index}>{line}</p>)}

                    {item.i3 != null && (
                      <>
                        <div className="mb-2">
                          <img
                            src={`${apiUrl}/img/${item.i3}`}
                            style={{ width: 250, height: 250 }}
                          />
                        </div>
                      </>
                    )}
                  </div>
                </div>

                {this.state.resulta[5].itens.length == index + 2 && (
                  <>
                    <>
                      <RiskAppreciationTable
                        code="hrn-12"
                        data={this.state.FormHrn}
                        resp={(value) => {
                          this.setState((prevState) => ({
                            DpMlhs: [...prevState.DpMlhs, value],
                          }));
                        }}
                        title="APRECIAÇÃO DE RISCO DEPOIS DAS AÇÕES DE MELHORIAS "
                      />

                      <div style={{ marginLeft: 50, marginTop: 10 }}>
                        <strong>
                          12.7 Sinalização, manuais, procedimentos e capacitação
                        </strong>
                      </div>

                      {apc2Item7 && apc2Item7.itens.length > 0 && (
                        <>
                          <div>
                            {apc2Item7.itens[0].text
                              .split("\n")
                              .map((line, index) => (
                                <p key={index}>{line}</p>
                              ))}
                          </div>
                          <div className="d-flex justify-content-center mt-2">
                            {apc2Item7.itens[0].img1 != null && (
                              <>
                                <div className="mb-2">
                                  <img
                                    src={`${apiUrl}/img/${apc2Item7.itens[0].img1}`}
                                    style={{
                                      width: 250,
                                      height: 250,
                                      border: "solid 1px",
                                      marginRight: 25,
                                    }}
                                  />
                                </div>
                              </>
                            )}
                            {apc2Item7.itens[0].img2 != null && (
                              <>
                                <div className="mb-2">
                                  <img
                                    src={`${apiUrl}/img/${apc2Item7.itens[0].img2}`}
                                    style={{
                                      width: 250,
                                      height: 250,
                                      border: "solid 1px",
                                    }}
                                  />
                                </div>
                              </>
                            )}
                          </div>
                          <div className="d-flex justify-content-center mt-2">
                            {apc2Item7.itens[0].img3 != null && (
                              <>
                                <div className="mb-2">
                                  <img
                                    src={`${apiUrl}/img/${apc2Item7.itens[0].img3}`}
                                    style={{
                                      width: 250,
                                      height: 250,
                                      border: "solid 1px",
                                      marginRight: 25,
                                    }}
                                  />
                                </div>
                              </>
                            )}

                            {apc2Item7.itens[0].img4 != null && (
                              <>
                                <div className="mb-2">
                                  <img
                                    src={`${apiUrl}/img/${apc2Item7.itens[0].img4}`}
                                    style={{
                                      width: 250,
                                      height: 250,
                                      border: "solid 1px",
                                    }}
                                  />
                                </div>
                              </>
                            )}
                          </div>
                        </>
                      )}
                      {apc2Item7.itens.length < 2 && (
                        <RiskAppreciationTable
                          code="hrn-13"
                          data={this.state.FormHrn}
                          resp={(value) => {
                            this.setState((prevState) => ({
                              AtMlhs: [...prevState.AtMlhs, value],
                            }));
                          }}
                          title="APRECIAÇÃO DE RISCO ANTES DAS AÇÕES DE MELHORIAS "
                        />
                      )}
                    </>
                  </>
                )}
              </div>
            </>
          ))
        : [];

    const resulta6 =
      this.state.resulta[6].itens.length > 0
        ? this.state.resulta[6].itens.slice(1).map((item, index) => (
            <>
              <div style={{ padding: 10 }}>
                <div style={{ border: "solid 1px", marginTop: 15 }}>
                  <div style={{ margin: 5 }}>
                    {item.t3 != null &&
                      item.t3
                        .split("\n")
                        .map((line, index) => <p key={index}>{line}</p>)}
                    {item.t1 != null && (
                      <>
                        <p>{item.t1}</p>
                      </>
                    )}
                    <div className="d-flex justify-content-center mt-2">
                      {item.i1 != null && (
                        <>
                          <div className="mb-2">
                            <img
                              src={`${apiUrl}/img/${item.i1}`}
                              style={{
                                width: 250,
                                height: 250,
                                marginRight: 25,
                                border: "solid 1px",
                              }}
                            />
                          </div>
                        </>
                      )}

                      {item.i2 != null && (
                        <>
                          <div className="mb-2">
                            <img
                              src={`${apiUrl}/img/${item.i2}`}
                              style={{
                                width: 250,
                                height: 250,
                                border: "solid 1px",
                              }}
                            />
                          </div>
                        </>
                      )}
                    </div>
                    <div className="d-flex justify-content-center mt-2">
                      {item.i3 != null && (
                        <>
                          <div className="mb-2">
                            <img
                              src={`${apiUrl}/img/${item.i3}`}
                              style={{
                                width: 250,
                                height: 250,
                                marginRight: 25,
                                border: "solid 1px",
                              }}
                            />
                          </div>
                        </>
                      )}
                      {item.i4 != null && (
                        <>
                          <div className="mb-2">
                            <img
                              src={`${apiUrl}/img/${item.i4}`}
                              style={{
                                width: 250,
                                height: 250,
                                border: "solid 1px",
                              }}
                            />
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                </div>

                {index === this.state.resulta[6].itens.length - 2 && (
                  <>
                    <RiskAppreciationTable
                      code="hrn-14"
                      data={this.state.FormHrn}
                      resp={(value) => {
                        this.setState((prevState) => ({
                          DpMlhs: [...prevState.DpMlhs, value],
                        }));
                      }}
                      title="APRECIAÇÃO DE RISCO DEPOIS DAS AÇÕES DE MELHORIAS "
                    />
                  </>
                )}
              </div>
            </>
          ))
        : [];

    const rst4 =
      result[4].itens.length > 0
        ? result[4].itens.map((item, index) => (
            <>
              <div style={{ padding: 10 }}>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    gap: "10px",
                  }}
                >
                  {item.img1 != null && (
                    <>
                      <img
                        src={`${apiUrl}/img/${item.img1}`}
                        style={{ width: 300, height: 300, border: "solid 1px" }}
                      />
                    </>
                  )}
                  {item.img2 != null && (
                    <>
                      <img
                        src={`${apiUrl}/img/${item.img2}`}
                        style={{ width: 300, height: 300, border: "solid 1px" }}
                      />
                    </>
                  )}
                </div>

                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    marginTop: 10,
                    gap: "10px",
                  }}
                >
                  {item.img3 != null && (
                    <>
                      <img
                        src={`${apiUrl}/img/${item.img3}`}
                        style={{ width: 300, height: 300, border: "solid 1px" }}
                      />
                    </>
                  )}
                  {item.img3 != null && (
                    <>
                      <img
                        src={`${apiUrl}/img/${item.img4}`}
                        style={{ width: 300, height: 300, border: "solid 1px" }}
                      />
                    </>
                  )}
                </div>
              </div>
            </>
          ))
        : [];

    const item10 =
      this.state.data.images.length > 0
        ? this.state.data.images.map((item, index) => (
            <>
              {index == 0 && (
                <div style={{ margin: 10, marginTop: 5 }}>
                  <strong>10-VISÃO GERAL DA MÁQUINA</strong>
                </div>
              )}
              <div className="vga" style={{ display: "table", margin: "auto" }}>
                <img
                  style={{ border: "solid 2px", width: 500, height: 500 }}
                  src={`${apiUrl}/img/` + item.img}
                />
              </div>
            </>
          ))
        : [];

    const totalP = [
      ...itens,
      ...item10,
      ...itens2,
      ...stc1,
      ...itens3za,
      ...resulta0,
      ...itens3z,
      ...gp1,
      ...stc2,
      ...itens3a,
      ...resulta1,
      ...itens3a3,
      ...rst1,
      ...stc3,
      ...itens3,
      ...resulta2,
      ...itens3a2,
      ...gp2,
      ...stc4,
      ...itens4,
      ...resulta3,
      ...itens4b,

      ...rst2,

      ...stc5,

      ...adc4,
      ...resulta4,
      ...itens4c,
      ...rst3,
      ...stc6,
      ...itens4d,
      ...resulta5,
      ...stc7,
      itens4daa,
      itens4da,
      ...resulta6,
      ...rst4,
    ];

    var LastPages = [
      <div className="dados">
        <div style={{ marginLeft: 20, marginBottom: 10 }}>
          <strong>13- VISÃO GERAL DE CONFORMIDADE</strong>
        </div>
        <div style={{ marginBottom: 10 }}>
          <strong>Antes das melhorias</strong>
        </div>

        <TabelaRisco data={this.state.AtMlhs} />

        <div className="pizza mt-5">
          <GraficoPizza data={this.state.AtMlhs} />
        </div>
      </div>,
      <div className="dados">
        <div className="mt-3 mb-3">
          <strong>Após melhorias</strong>
        </div>
        <TabelaRisco data={this.state.DpMlhs} />
        <div className="pizza mt-5">
          <GraficoPizza data={this.state.DpMlhs} />
        </div>
        <br />
        <div style={{ display: "table", margin: "auto" }}>
          <img src={h5} />
        </div>
      </div>,
      <div className="dados">
        <div style={{ marginBottom: 10 }}>
          <strong>14 - CONSIDERAÇÕES FINAIS</strong>
        </div>

        <div>
          <p>
            A máquina denominada{" "}
            <strong>{this.ShowText("dffhdf6h1str")}</strong> com os sistemas de
            segurança, arquitetura elétrica e interface de segurança deve
            atender no mínimo <strong>categoria {this.state.ctg}</strong> de
            segurança, levando em consideração as ações propostas como sugestão
            de melhoria referente a cada item avaliado, segundo NR-12, NBR
            12.153: 2015, ISO TR 24119: 2015, ISO 13849-1: 2019 e NBR ISO 12100:
            2013.
          </p>
        </div>

        <div>
          <p>
            Vale ressaltar que, as imagens contidas neste documento são de uso
            orientativo. A adequação deve ser executada por profissionais
            habilitados e capacitados para este fim.
          </p>
        </div>

        <div>
          <p>
            Deve ser elaborado projeto elétrico / mecânico dos sistemas de
            segurança com base nos itens aplicáveis da NR-12 levando como base a
            apreciação de riscos.
          </p>
        </div>

        <div>
          <p>
            Proteções fixas, móveis e dispositivos opto eletrônicos devem
            atender as distancias de segurança do ANEXO 1 da NR-12 e EN ISO
            13857: 2021.
          </p>
        </div>
      </div>,
      <div className="dados">
        <div style={{ marginBottom: 10 }}>
          <strong>15- CORPO TÉCNICO RESPONSÁVEL</strong>
        </div>

        <div>
          <p>
            Este documento é composto por{" "}
            <strong>{this.NumeroPorExtenso(totalP.length + 6)}</strong> páginas
            numeradas e assinado na última página pelo corpo técnico responsável
            pela avaliação e elaboração da “A.R Apreciação de Riscos”{" "}
          </p>
          <div>
            <strong>
              <TextView
                type="text"
                code="d6554fgj"
                data={this.state.TextView}
              />
            </strong>
          </div>
        </div>

        <div className="aln-f">
          <div className="sub">
            <hr />
            {this.state.data.tecs.map((item, index) => (
              <div style={{ marginBottom: 10 }}>
                <strong>{item.nome}</strong> - {item.func}
              </div>
            ))}
            <strong>{this.state.data.executante.razao_social}</strong>
          </div>
        </div>
      </div>,
      <>
        <div className="al-c">
          <strong>Anexos:</strong>
        </div>
        <div className="al-c">
          <strong>ART de Segurança:</strong>
        </div>
      </>,
    ];

    const combinedPages = [
      ...itens,
      ...item10,
      ...itens2,
      ...stc1,
      ...itens3za,
      ...resulta0,
      ...itens3z,
      ...gp1,
      ...stc2,
      ...itens3a,
      ...resulta1,
      ...itens3a3,
      ...rst1,
      ...stc3,
      ...itens3,
      ...resulta2,
      ...itens3a2,
      ...gp2,
      ...stc4,
      ...itens4,
      ...resulta3,
      ...itens4b,

      ...rst2,

      ...stc5,

      ...adc4,
      ...resulta4,
      ...itens4c,
      ...rst3,
      ...stc6,
      ...itens4d,
      ...resulta5,

      itens4daa,
      ...stc7,
      itens4da,
      ...resulta6,
      ...rst4,
      ...LastPages,
    ];

    return (
      <>
        <section id="p2" className="ac5">
          <div class="ctn">
            <div className="al-c" style={{ marginTop: 30 }}>
              <strong>A.R</strong>
            </div>
            <div className="al-c" style={{ marginBottom: 30 }}>
              <strong>APRECIAÇÃO DE RISCOS</strong>
            </div>
            <div style={{ display: "table", margin: "auto" }}>
              <div>
                {" "}
                <strong>EXECUTANTE:</strong>
              </div>
            </div>
            <div>
              <div className="al-c">
                {" "}
                <strong>
                  {this.state.data.executante.razao_social.toUpperCase()}
                </strong>
              </div>
            </div>
            <div className="al-c">
              <img src={`${apiUrl}/img/${this.state.data.executante.logo}`} />
            </div>
            <br />
            <div className="al-c">
              <strong>CONTRATANTE:</strong>
            </div>

            <div className="al-c">
              {" "}
              <strong>
                {this.state.data.contratante.razao_social.toUpperCase()}
              </strong>
            </div>
            <div className="al-c">
              <img src={`${apiUrl}/img/${this.state.data.contratante.logo}`} />
            </div>
            <div style={{ marginLeft: 50 }}>
              <div>
                <strong>
                  MÁQUINA / EQUIPAMENTO: {this.ShowText("dffhdf6h1str")}{" "}
                </strong>
              </div>
              <br />
              <div>
                <strong>
                  ART VINCULADA: Nº {this.ShowText("dffg656h1str")}{" "}
                </strong>
              </div>
            </div>

            <div className="cdd">
              <div className="al-c">
                <strong>{this.ShowText("dffgutdrstr")}</strong>
              </div>
              <div className="al-c">
                <strong>{this.ShowText("dfghfdfgdstr")}</strong>
              </div>
            </div>
            <div
              class="aqv-d"
              style={{
                display: "flex",
                justifyContent: "flex-end",
                marginRight: 50,
                marginBottom: 20,
              }}
            >
              <div style={{ display: "table", fontSize: 20 }}>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <div class="al-c" style={{ fontSize: 15 }}>
                    Arquivos digitais
                  </div>

                  <ImageView code="grtssgfysh" data={this.state.ImageView} />
                </div>
              </div>
            </div>

            <div className="txt-a1">
              <p style={{ fontSize: 13 }}>
                As informações deste documento são confidenciais. Informações
                fornecidas e destinadas ao uso interno da empresa
                executante/contratante não sendo permitida a cópia, reprodução
                total ou parcial de qualquer tipo de informação deste documento
                sem a expressa autorização das partes envolvidas sob as penas da
                lei.
              </p>
            </div>

            <div class="ex-s">
              {" "}
              <TextView
                type="text"
                code="dfgdtyud+sdr51tr"
                data={this.state.TextView}
              />
            </div>
          </div>
        </section>

        {combinedPages.map((PageComponent, index) => (
          <section id="p2" className="ac5 pvw" style={{ marginBottom: 0 }}>
            <div class="ctn">
              <div class="area-mtp">
                <div class="area-a">
                  <div>
                    <strong>Data:</strong> {this.ShowText("dfghfdfgdstr")}
                  </div>
                  <div className="aln-e">
                    <strong>Rev:</strong>{" "}
                    <TextView
                      type="text"
                      code="drt6+62rse+2tr"
                      data={this.state.TextView}
                    />
                  </div>
                  <div className="aln-e">
                    <strong>Número:</strong>{" "}
                    <TextView
                      type="text"
                      code="32+fg2+trhstr"
                      data={this.state.TextView}
                    />
                  </div>
                  <div>
                    <strong>Página:</strong> {` `} {index + 2} de{" "}
                    {combinedPages.length + 1}
                  </div>
                </div>

                <div class="area-b">
                  <div>A.R. APRECIAÇÃO DE RISCOS</div>
                  <div>{this.ShowText("dffhdf6h1str")}</div>
                </div>

                <div class="area-c">
                  <img
                    alt="logo"
                    src={`${apiUrl}/img/${this.state.data.contratante.logo}`}
                  />
                </div>
              </div>

              <div>
                <div style={{ marginLeft: 10, marginBottom: -10 }}>
                  {PageComponent}
                </div>
              </div>

              <div className="ex-s">
                {" "}
                <TextView
                  type="text"
                  code="dfgdtyud+sdr51tr"
                  data={this.state.TextView}
                />
              </div>
            </div>
          </section>
        ))}
      </>
    );
  }
}

export default AdrView;
