import React from "react";
import { render } from "react-dom";
//import './index.css';
import App from "./App";

//import 'bootstrap/dist/css/bootstrap.min.css';
//import "./main.css"
import { BrowserRouter } from "react-router-dom";
import ErrorBoundary from "./Comps/ErrorBoundary";
//import Sidebar from './paginas/Sidebar';
//import Header from './paginas/Header';
render(
  <BrowserRouter>
    <ErrorBoundary>
      <App />
    </ErrorBoundary>
  </BrowserRouter>,
  document.querySelector("#root")
);
