import React, { Component } from "react";
import error from "../../images/error.svg";

import { toast, ToastContainer } from "react-toastify";
import { apiUrl } from "../../config";
class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    // Atualiza o estado para exibir a interface de fallback
    return { hasError: true };
  }

  componentDidCatch(error, info) {
    // Você pode logar o erro ou enviar para um serviço externo
    console.error("Erro capturado: ", error);
    console.error("Informações adicionais: ", info);
    this.sendErrorToServer(error, info);
  }

  sendErrorToServer(error, info) {
    const errorDetails = {
      message: error.message,
      stack: error.stack,
      componentStack: info.componentStack,
    };

    fetch(`${apiUrl}/report`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(errorDetails),
    })
      .then((response) => response.json())
      .then((data) => {
        toast.success("O Erro foi reportado");
      })
      .catch((err) => {
        toast.error("falha na comunicação com o servidor!");
      });
  }

  render() {
    if (this.state.hasError) {
      return (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              textAlign: "center",
            }}
          >
            <img src={error} width={500} />
            <div style={{ marginTop: 50 }}></div>
            <strong>
              Desculpe, houve um problema técnico e o aplicativo falhou.{" "}
            </strong>
            <p>
              Estamos trabalhando para resolver a situação o mais rápido
              possível. Agradecemos pela sua paciência!"
            </p>
          </div>

          <ToastContainer />
        </div>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
