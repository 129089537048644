import {
  faPen,
  faPlus,
  faSave,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { Component } from "react";
import { apiUrl } from "../../config";
import { toast } from "react-toastify";
import { StaticDialog } from "react-st-modal";
import ImageMarker from "../ImageMarker";

class Apc extends Component {
  constructor(props) {
    super(props);
    // Define o estado inicial
    this.state = {
      grupo: [],
      delete: false,
      item: undefined,
      textList: [],
      modal: false,
      psq: "",
      id: null,
      open: false,
      openItem: null,
      imgPrev: null,
      selectedImage: null,
      Galery: [],
      slc: -1,
      imgSlc: null,
      apc: [],
      ntext: "",
      edit: "",
      trash: {},
    };
  }

  // Método para incrementar o contador
  handleClick = () => {
    this.setState((prevState) => ({ count: prevState.count + 1 }));
  };

  componentDidMount() {
    const matchedItem = this.props.data.find(
      (item) => item.code === this.props.code
    );

    if (matchedItem != undefined) {
      console.log(JSON.stringify(matchedItem));
      this.setState({ apc: matchedItem.itens });
    } else {
      this.setState({ apc: [] });
    }
  }

  handleChange2() {
    var text = this.state.ntext;

    if (text.trim().length == 0) {
      //event.target.value = '';
    } else {
      const id = toast.loading("Salvando");

      fetch(`${apiUrl}/update-apc/`, {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          id: this.state.id,
          code: this.state.code,
          text,
          type: this.state.type,
        }),
      }).then((response) => {
        toast.update(id, {
          render: "Salvo!",
          type: "success",
          isLoading: false,
          autoClose: 3000,
        });

        response.json().then((data) => {
          const newData = [...this.state.apc]; // Cria uma cópia do array
          newData[this.state.index] = {
            ...newData[this.state.index],
            [this.state.type]: this.state.ntext,
          }; // Altera os campos necessários
          this.setState({ apc: newData }); // Atualiza

          this.setState({
            modal: false,
          });
        });
      });
    }
  }

  handleChange(id, type, index) {
    const file = this.state.selectedImage;

    const formData = new FormData();
    formData.append("imagem", file);
    formData.append("id", id);
    formData.append("type", type);

    fetch(`${apiUrl}/img-apc`, {
      method: "POST",
      body: formData,
    }).then((response) => {
      if (response.status == 200) {
        response.json().then((data) => {
          toast.success("Sucesso!");
          this.setState((prevState) => {
            const novoGrupo2 = [...prevState.apc];

            novoGrupo2[index][type] = data.img;

            return { apc: novoGrupo2 };
          });

          this.setState({
            open: false,
            openItem: null,
            imgPrev: null,
            slc: -1,
            Galery: [],
            selectedImage: null,
          });
        });
      }
    });
  }

  LoadGalery() {
    fetch(`${apiUrl}/galery/list`, {
      method: "GET",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
    }).then((response) => {
      if (response.status == 200) {
        response.json().then((data) => {
          this.setState({ Galery: data });
        });
      }
    });
  }

  render() {
    const listaFiltrada = this.state.textList.filter((item) =>
      item.text.toLowerCase().includes(this.state.psq.toLowerCase())
    );

    if (window.location.pathname.includes("/solicitante/preview/") == false) {
      return (
        <>
          <div style={{ border: "solid 1px", marginTop: 15 }}>
            <div style={{ textAlign: "center", background: "#bdd6ee" }}>
              <strong>AÇÕES PROPOSTAS COMO SUGESTÃO DE ADEQUAÇÃO</strong>
            </div>
            <div style={{ margin: 5 }}>
              {this.state.grupo.length > 0 ? (
                <>
                  {this.state.grupo.map((item, index) => (
                    <>
                      {item.type == 0 ? (
                        <>
                          {item.text != "" && (
                            <>
                              <p>{item.text}</p>
                            </>
                          )}
                        </>
                      ) : (
                        <>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              marginBottom: 5,
                            }}
                          >
                            {item.img1 != null && (
                              <>
                                <img
                                  src={`${apiUrl}/img/${item.img1}`}
                                  style={{
                                    width: 250,
                                    height: 210,
                                    border: "solid 1px",
                                  }}
                                />
                              </>
                            )}

                            {item.img2 != null && (
                              <div style={{ marginLeft: 10, marginRight: 10 }}>
                                <img
                                  src={`${apiUrl}/img/${item.img2}`}
                                  style={{
                                    width: 250,
                                    height: 210,
                                    border: "solid 1px",
                                  }}
                                />
                              </div>
                            )}

                            {item.img3 != null && (
                              <>
                                <img
                                  src={`${apiUrl}/img/${item.img3}`}
                                  style={{
                                    width: 250,
                                    height: 210,
                                    border: "solid 1px",
                                  }}
                                />
                              </>
                            )}
                          </div>
                        </>
                      )}
                    </>
                  ))}
                </>
              ) : (
                <>Vázio!</>
              )}
            </div>
          </div>
        </>
      );
    }

    return (
      <>
        <div class="card">
          <div class="card-header d-flex justify-content-between">
            <h3>
              AÇÕES PROPOSTAS COMO SUGESTÃO DE ADEQUAÇÃO
              {process.env.NODE_ENV === "development" &&
                " -" + " " + this.props.code}
            </h3>
            <div class="custom-file"></div>
          </div>
          <div class="card-body">
            {this.state.apc.length > 0 ? (
              <>
                {this.state.apc.map((item, index) => (
                  <>
                    <div class="card mb-3">
                      <div class="card-header d-flex justify-content-between">
                        <h3>
                          Grupo:{" "}
                          {item.type == 0
                            ? "texto + imagens"
                            : "página de texto"}
                        </h3>
                        <button
                          className="btn btn-danger"
                          onClick={() => {
                            this.setState({ delete: true, idd: item.id });
                          }}
                        >
                          <FontAwesomeIcon icon={faTrash} />
                        </button>
                      </div>
                      {item.type == 0 ? (
                        <>
                          {" "}
                          <div class="card-body">
                            <div
                              style={{
                                border: "solid 1px #ccc",
                                borderRadius: 5,
                                padding: 10,
                                cursor: "pointer",
                              }}
                              onClick={() => {
                                fetch(`${apiUrl}/text-list`, {
                                  method: "GET",
                                  credentials: "include",
                                  headers: {
                                    "Content-Type": "application/json",
                                  },
                                }).then((response) => {
                                  if (response.status == 200) {
                                    response.json().then((data) => {
                                      this.setState({ textList: data });
                                    });
                                  }
                                });

                                this.setState({
                                  modal: true,
                                  ntext: item.t1 == null ? "" : item.t1,
                                  id: item.id,
                                  code: item.ref,
                                  index,
                                  type: "t1",
                                });
                              }}
                            >
                              <span>
                                {item.t1 == null ? "Digite:" : item.t1}
                              </span>
                            </div>
                            <div className="mb-3">
                              <div className="area-8 gp-imgs">
                                {this.props.type == 2 && index == 0 ? (
                                  <>
                                    {item.i1 != null ? (
                                      <>
                                        <div class="view mt-3">
                                          <img
                                            src={`${apiUrl}/img/${item.i1}`}
                                          />
                                          <div className="ac">
                                            <div className="arb">
                                              <button
                                                className="b1"
                                                onClick={() => {
                                                  this.setState({
                                                    edit: {
                                                      id: item.id,
                                                      img: item.i1,
                                                      q: "i1",
                                                      index,
                                                    },
                                                  });
                                                }}
                                              >
                                                <FontAwesomeIcon
                                                  color="#fff"
                                                  icon={faPen}
                                                />
                                              </button>
                                              <button
                                                className="b2"
                                                onClick={() => {
                                                  this.setState({
                                                    trash: {
                                                      id: item.id,
                                                      index,
                                                      type: "i1",
                                                    },
                                                  });
                                                }}
                                              >
                                                <FontAwesomeIcon
                                                  color="#fff"
                                                  icon={faTrash}
                                                />
                                              </button>
                                            </div>
                                          </div>
                                        </div>
                                      </>
                                    ) : (
                                      <>
                                        <div
                                          class="add"
                                          onClick={() => {
                                            this.LoadGalery();

                                            this.setState({
                                              open: true,
                                              openItem: {
                                                id: item.id,
                                                index,
                                                file: item.i1,
                                                img: "i1",
                                              },
                                            });
                                          }}
                                        >
                                          <FontAwesomeIcon
                                            icon={faPlus}
                                            color="#282828"
                                          />{" "}
                                        </div>
                                      </>
                                    )}
                                    {item.i2 != null ? (
                                      <>
                                        <div class="view mt-3">
                                          <img
                                            src={`${apiUrl}/img/${item.i2}`}
                                          />
                                          <div className="ac">
                                            <div className="arb">
                                              <button
                                                className="b1"
                                                onClick={() => {
                                                  this.setState({
                                                    edit: {
                                                      id: item.id,
                                                      img: item.i2,
                                                      q: "i2",
                                                      index,
                                                    },
                                                  });
                                                }}
                                              >
                                                <FontAwesomeIcon
                                                  color="#fff"
                                                  icon={faPen}
                                                />
                                              </button>
                                              <button
                                                className="b2"
                                                onClick={() => {
                                                  this.setState({
                                                    trash: {
                                                      id: item.id,
                                                      index,
                                                      type: "i2",
                                                    },
                                                  });
                                                }}
                                              >
                                                <FontAwesomeIcon
                                                  color="#fff"
                                                  icon={faTrash}
                                                />
                                              </button>
                                            </div>
                                          </div>
                                        </div>
                                      </>
                                    ) : (
                                      <>
                                        <div
                                          class="add"
                                          onClick={() => {
                                            this.LoadGalery();

                                            this.setState({
                                              open: true,
                                              openItem: {
                                                id: item.id,
                                                index,
                                                file: item.i2,
                                                img: "i2",
                                              },
                                            });
                                          }}
                                        >
                                          <FontAwesomeIcon
                                            icon={faPlus}
                                            color="#282828"
                                          />{" "}
                                        </div>
                                      </>
                                    )}
                                  </>
                                ) : (
                                  <>
                                    {item.i1 != null ? (
                                      <>
                                        <div class="view mt-3">
                                          <img
                                            src={`${apiUrl}/img/${item.i1}`}
                                          />
                                          <div className="ac">
                                            <div className="arb">
                                              <button
                                                className="b1"
                                                onClick={() => {
                                                  this.setState({
                                                    edit: {
                                                      id: item.id,
                                                      img: item.i1,
                                                      q: "i1",
                                                      index,
                                                    },
                                                  });
                                                }}
                                              >
                                                <FontAwesomeIcon
                                                  color="#fff"
                                                  icon={faPen}
                                                />
                                              </button>
                                              <button
                                                className="b2"
                                                onClick={() => {
                                                  this.setState({
                                                    trash: {
                                                      id: item.id,
                                                      index,
                                                      type: "i1",
                                                    },
                                                  });
                                                }}
                                              >
                                                <FontAwesomeIcon
                                                  color="#fff"
                                                  icon={faTrash}
                                                />
                                              </button>
                                            </div>
                                          </div>
                                        </div>
                                      </>
                                    ) : (
                                      <>
                                        <div
                                          class="add"
                                          onClick={() => {
                                            this.LoadGalery();

                                            this.setState({
                                              open: true,
                                              openItem: {
                                                id: item.id,
                                                index,
                                                file: item.i1,
                                                img: "i1",
                                              },
                                            });
                                          }}
                                        >
                                          <FontAwesomeIcon
                                            icon={faPlus}
                                            color="#282828"
                                          />{" "}
                                        </div>
                                      </>
                                    )}
                                    {item.i2 != null ? (
                                      <>
                                        <div class="view mt-3">
                                          <img
                                            src={`${apiUrl}/img/${item.i2}`}
                                          />
                                          <div className="ac">
                                            <div className="arb">
                                              <button
                                                className="b1"
                                                onClick={() => {
                                                  this.setState({
                                                    edit: {
                                                      id: item.id,
                                                      img: item.i2,
                                                      q: "i2",
                                                      index,
                                                    },
                                                  });
                                                }}
                                              >
                                                <FontAwesomeIcon
                                                  color="#fff"
                                                  icon={faPen}
                                                />
                                              </button>
                                              <button
                                                className="b2"
                                                onClick={() => {
                                                  this.setState({
                                                    trash: {
                                                      id: item.id,
                                                      index,
                                                      type: "i2",
                                                    },
                                                  });
                                                }}
                                              >
                                                <FontAwesomeIcon
                                                  color="#fff"
                                                  icon={faTrash}
                                                />
                                              </button>
                                            </div>
                                          </div>
                                        </div>
                                      </>
                                    ) : (
                                      <>
                                        <div
                                          class="add"
                                          onClick={() => {
                                            this.LoadGalery();

                                            this.setState({
                                              open: true,
                                              openItem: {
                                                id: item.id,
                                                index,
                                                file: item.i2,
                                                img: "i2",
                                              },
                                            });
                                          }}
                                        >
                                          <FontAwesomeIcon
                                            icon={faPlus}
                                            color="#282828"
                                          />{" "}
                                        </div>
                                      </>
                                    )}
                                  </>
                                )}
                              </div>
                            </div>

                            {this.props.exs != undefined && (
                              <>
                                <div
                                  style={{
                                    border: "solid 1px #ccc",
                                    borderRadius: 5,
                                    padding: 10,
                                    cursor: "pointer",
                                  }}
                                  onClick={() => {
                                    fetch(`${apiUrl}/text-list`, {
                                      method: "GET",
                                      credentials: "include",
                                      headers: {
                                        "Content-Type": "application/json",
                                      },
                                    }).then((response) => {
                                      if (response.status == 200) {
                                        response.json().then((data) => {
                                          this.setState({ textList: data });
                                        });
                                      }
                                    });

                                    this.setState({
                                      modal: true,
                                      ntext: item.t2 == null ? "" : item.t2,
                                      id: item.id,
                                      code: item.ref,
                                      index,
                                      type: "t2",
                                    });
                                  }}
                                >
                                  <span>
                                    {item.t2 == null ? "Digite:" : item.t2}
                                  </span>
                                </div>

                                {this.props.maxi != undefined ? (
                                  ""
                                ) : (
                                  <div className="area-8 gp-imgs">
                                    {item.i3 != null ? (
                                      <>
                                        <div class="view mt-3">
                                          <img
                                            src={`${apiUrl}/img/${item.i3}`}
                                          />
                                          <div className="ac">
                                            <div className="arb">
                                              <button
                                                className="b1"
                                                onClick={() => {
                                                  this.setState({
                                                    edit: {
                                                      id: item.id,
                                                      img: item.i3,
                                                      q: "i3",
                                                      index,
                                                    },
                                                  });
                                                }}
                                              >
                                                <FontAwesomeIcon
                                                  color="#fff"
                                                  icon={faPen}
                                                />
                                              </button>
                                              <button
                                                className="b2"
                                                onClick={() => {
                                                  this.setState({
                                                    trash: {
                                                      id: item.id,
                                                      index,
                                                      type: "i3",
                                                    },
                                                  });
                                                }}
                                              >
                                                <FontAwesomeIcon
                                                  color="#fff"
                                                  icon={faTrash}
                                                />
                                              </button>
                                            </div>
                                          </div>
                                        </div>
                                      </>
                                    ) : (
                                      <>
                                        <div
                                          class="add"
                                          onClick={() => {
                                            this.LoadGalery();

                                            this.setState({
                                              open: true,
                                              openItem: {
                                                id: item.id,
                                                index,
                                                file: item.i3,
                                                img: "i3",
                                              },
                                            });
                                          }}
                                        >
                                          <FontAwesomeIcon
                                            icon={faPlus}
                                            color="#282828"
                                          />{" "}
                                        </div>
                                      </>
                                    )}

                                    {item.i4 != null ? (
                                      <>
                                        <div class="view mt-3">
                                          <img
                                            src={`${apiUrl}/img/${item.i4}`}
                                          />
                                          <div className="ac">
                                            <div className="arb">
                                              <button
                                                className="b1"
                                                onClick={() => {
                                                  this.setState({
                                                    edit: {
                                                      id: item.id,
                                                      img: item.i4,
                                                      q: "i4",
                                                      index,
                                                    },
                                                  });
                                                }}
                                              >
                                                <FontAwesomeIcon
                                                  color="#fff"
                                                  icon={faPen}
                                                />
                                              </button>
                                              <button
                                                className="b2"
                                                onClick={() => {
                                                  this.setState({
                                                    trash: {
                                                      id: item.id,
                                                      index,
                                                      type: "i4",
                                                    },
                                                  });
                                                }}
                                              >
                                                <FontAwesomeIcon
                                                  color="#fff"
                                                  icon={faTrash}
                                                />
                                              </button>
                                            </div>
                                          </div>
                                        </div>
                                      </>
                                    ) : (
                                      <>
                                        <div
                                          class="add"
                                          onClick={() => {
                                            this.LoadGalery();

                                            this.setState({
                                              open: true,
                                              openItem: {
                                                id: item.id,
                                                index,
                                                file: item.i4,
                                                img: "i4",
                                              },
                                            });
                                          }}
                                        >
                                          <FontAwesomeIcon
                                            icon={faPlus}
                                            color="#282828"
                                          />{" "}
                                        </div>
                                      </>
                                    )}
                                  </div>
                                )}
                              </>
                            )}
                          </div>
                        </>
                      ) : (
                        <div class="card-body">
                          <div
                            style={{
                              border: "solid 1px #ccc",
                              borderRadius: 5,
                              padding: 10,
                              height: 250,
                              overflowX: "auto",
                              cursor: "pointer",
                            }}
                            onClick={() => {
                              fetch(`${apiUrl}/text-list`, {
                                method: "GET",
                                credentials: "include",
                                headers: {
                                  "Content-Type": "application/json",
                                },
                              }).then((response) => {
                                if (response.status == 200) {
                                  response.json().then((data) => {
                                    this.setState({ textList: data });
                                  });
                                }
                              });

                              this.setState({
                                modal: true,
                                ntext: item.t3 == null ? "" : item.t3,
                                id: item.id,
                                code: item.ref,
                                index,
                                type: "t3",
                              });
                            }}
                          >
                            <span>{item.t3 == null ? "Digite:" : item.t3}</span>
                          </div>
                        </div>
                      )}
                    </div>
                  </>
                ))}

                <div class="d-flex justify-content-center">
                  <button
                    class="btn btn-primary btn-upl"
                    onClick={(e) => {
                      e.target.classList.add("load");

                      fetch(
                        `${apiUrl}/add-apc/${
                          window.location.pathname.split("/")[3]
                        }`,
                        {
                          method: "POST",
                          credentials: "include",
                          headers: {
                            "Content-Type": "application/json",
                          },
                          body: JSON.stringify({
                            type: 0,
                            code: this.props.code,
                          }),
                        }
                      ).then((response) => {
                        e.target.classList.remove("load");
                        if (response.status == 200) {
                          response.json().then((data) => {
                            toast.success("Grupo adicionado");
                            this.setState((prevState) => ({
                              apc: [
                                ...prevState.apc,
                                {
                                  id: data.id,
                                  ref: data.code,
                                  type: 0,
                                  item: [{}],
                                }, // Adiciona um novo item com imgs como array vazio
                              ],
                            }));
                          });
                        }
                      });
                    }}
                  >
                    <FontAwesomeIcon icon={faPlus} /> Texto + imagens
                  </button>
                  <button
                    style={{ marginLeft: 10 }}
                    class="btn btn-primary btn-upl"
                    onClick={(e) => {
                      e.target.classList.add("load");

                      fetch(
                        `${apiUrl}/add-apc/${
                          window.location.pathname.split("/")[3]
                        }`,
                        {
                          method: "POST",
                          credentials: "include",
                          headers: {
                            "Content-Type": "application/json",
                          },
                          body: JSON.stringify({
                            type: 1,
                            code: this.props.code,
                          }),
                        }
                      ).then((response) => {
                        e.target.classList.remove("load");
                        if (response.status == 200) {
                          response.json().then((data) => {
                            toast.success("Grupo adicionado");
                            this.setState((prevState) => ({
                              apc: [
                                ...prevState.apc,
                                {
                                  id: data.id,
                                  ref: data.code,
                                  type: 1,
                                  item: [{}],
                                }, // Adiciona um novo item com imgs como array vazio
                              ],
                            }));
                          });
                        }
                      });
                    }}
                  >
                    <FontAwesomeIcon icon={faPlus} /> Página de texto
                  </button>
                </div>
              </>
            ) : (
              <>
                <div class="d-flex justify-content-center">
                  <button
                    class="btn btn-primary btn-upl"
                    onClick={(e) => {
                      e.target.classList.add("load");

                      fetch(
                        `${apiUrl}/add-apc/${
                          window.location.pathname.split("/")[3]
                        }`,
                        {
                          method: "POST",
                          credentials: "include",
                          headers: {
                            "Content-Type": "application/json",
                          },
                          body: JSON.stringify({
                            type: 0,
                            code: this.props.code,
                          }),
                        }
                      ).then((response) => {
                        e.target.classList.remove("load");
                        if (response.status == 200) {
                          response.json().then((data) => {
                            toast.success("Grupo adicionado");
                            this.setState((prevState) => ({
                              apc: [
                                ...prevState.apc,
                                {
                                  id: data.id,
                                  ref: data.code,
                                  type: 0,
                                  item: [{}],
                                }, // Adiciona um novo item com imgs como array vazio
                              ],
                            }));
                          });
                        }
                      });
                    }}
                  >
                    <FontAwesomeIcon icon={faPlus} /> Adicionar grupo
                  </button>
                </div>
              </>
            )}
          </div>
        </div>

        <StaticDialog
          isOpen={this.state.modal}
          title="TEXTO:"
          onAfterClose={(result) => {
            this.setState({
              modal: false,
              id: null,
              type: null,
              code: null,
              ntext: null,
            });
          }}
        >
          <div style={{ padding: 10 }}>
            {this.state.type == "t3" ? (
              <>
                <div style={{ position: "relative" }}>
                  <textarea
                    class="form-control form-control-sm"
                    style={{ height: 100 }}
                    maxLength={this.props.ptext || 1000}
                    rows={3}
                    onChange={(e) => {
                      const value = e.target.value;
                      const lines = value.split("\n");

                      this.setState({ ntext: value });
                    }}
                    value={this.state.ntext}
                    placeholder="Digite:"
                  ></textarea>
                  <div className="ctt">
                    {this.state.ntext != undefined && this.state.ntext.length}/
                    {this.props.ptext || 1000}
                  </div>
                </div>
              </>
            ) : (
              <>
                <div style={{ position: "relative" }}>
                  <textarea
                    class="form-control form-control-sm"
                    style={{ height: 100 }}
                    maxLength={this.props.maxText || 1000}
                    rows={3}
                    onChange={(e) => {
                      const value = e.target.value;
                      const lines = value.split("\n");

                      this.setState({ ntext: value });
                    }}
                    value={this.state.ntext}
                    placeholder="Digite:"
                  ></textarea>
                  <div className="ctt">
                    {this.state.ntext != undefined && this.state.ntext.length}/
                    {this.props.maxText || 1000}
                  </div>
                </div>
              </>
            )}

            <input
              style={{
                width: "100%",
                height: 35,
                marginTop: 10,
              }}
              type="text"
              onChange={(e) => {
                this.setState({ psq: e.target.value });
              }}
              placeholder="Pesquisar:"
            />
            <div class="area-ptxt">
              {listaFiltrada.length > 0 ? (
                <>
                  <ul>
                    {listaFiltrada.map((item) => (
                      <>
                        <li
                          onClick={() => {
                            this.setState({
                              ntext: item.text.substring(0, this.props.maxText),
                            });
                          }}
                        >
                          {item.text}
                        </li>
                      </>
                    ))}
                  </ul>
                </>
              ) : (
                <>Não há nada aqui!</>
              )}
            </div>
          </div>

          <hr />

          <div
            class="d-flex justify-content-end"
            style={{
              margin: 10,
            }}
          >
            <button
              type="button"
              class="btn btn-primary"
              onClick={() => {
                this.handleChange2();
              }}
              disabled={this.state.text != this.state.ntext ? false : true}
            >
              <FontAwesomeIcon icon={faSave} color="#fff" /> Salvar
            </button>
          </div>
        </StaticDialog>

        <StaticDialog
          isOpen={Object.keys(this.state.trash).length > 0}
          title="Atenção"
          onAfterClose={(result) => {
            this.setState({ trash: {} });
            // do something with dialog result
          }}
        >
          <div style={{ margin: 10 }}>
            Uma vez deletado, não será possível recuperar
          </div>

          <div className="modal-btn">
            <button
              className="btn btn-danger"
              onClick={(e) => {
                e.target.classList.add("load");
                fetch(`${apiUrl}/deletar-imagem-apc`, {
                  method: "POST",
                  credentials: "include",
                  headers: {
                    "Content-Type": "application/json",
                  },
                  body: JSON.stringify({
                    id: this.state.trash.id,
                    type: this.state.trash.type,
                  }),
                }).then((response) => {
                  e.target.classList.remove("load");

                  if (response.status == 200) {
                    toast.success("Imagem removida com sucesso!");
                    this.setState((prevState) => {
                      const novoGrupo2 = [...prevState.apc];

                      novoGrupo2[this.state.trash.index][
                        this.state.trash.type
                      ] = null;

                      return { apc: novoGrupo2 };
                    });
                    this.setState({ trash: {} });
                  }
                });
              }}
            >
              <FontAwesomeIcon icon={faTrash} color="#fff" /> Remover
            </button>
          </div>
        </StaticDialog>

        <StaticDialog
          isOpen={this.state.delete}
          title="Atenção"
          onAfterClose={(result) => {
            this.setState({ delete: false, item: {} });
            // do something with dialog result
          }}
        >
          <div style={{ margin: 10 }}>
            Uma vez deletado, não será possível recuperar
          </div>

          <div className="modal-btn">
            <button
              className="btn btn-danger"
              onClick={(e) => {
                e.target.classList.add("load");
                var id = this.state.idd;

                fetch(`${apiUrl}/remove-apc/${id}`, {
                  method: "DELETE",
                })
                  .then((response) => {
                    if (response.status == 200) {
                      e.target.classList.remove("load");
                      this.setState((prevState) => ({
                        apc: prevState.apc.filter(
                          (item) => item.id !== this.state.idd
                        ),
                        delete: false,
                      }));
                      toast.success("Sucesso!");
                    }
                  })
                  .catch(() => {
                    e.classList.remove("load");
                    toast.error("Falha na comunicação!");
                  });
              }}
            >
              <FontAwesomeIcon icon={faTrash} color="#fff" /> Remover
            </button>
          </div>
        </StaticDialog>

        <StaticDialog
          isOpen={this.state.open}
          title="Galeria"
          onAfterClose={(result) => {
            this.setState({
              open: false,
              openItem: null,
              imgPrev: null,
              slc: -1,
              Galery: [],
              selectedImage: null,
            });
            // do something with dialog result
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              padding: 10,
              flexWrap: "wrap",
              gap: 10,
              maxHeight: 400,
              overflowX: "auto",
            }}
          >
            {this.state.openItem != null ? (
              <>
                <div class="area-8" style={{ marginRight: -10 }}>
                  {this.state.imgPrev != null ? (
                    <>
                      <div class="preview" style={{ marginTop: 0 }}>
                        <div class="edit">
                          <input
                            type="file"
                            onChange={(e) => {
                              const file = e.target.files[0];
                              if (file) {
                                const fileType = file.type;
                                if (!fileType.startsWith("image/")) {
                                  toast.warn(
                                    "Por favor, selecione uma imagem válida!"
                                  );
                                  e.target.value = null;
                                } else {
                                  const reader = new FileReader();
                                  reader.onloadend = () => {
                                    this.setState({
                                      slc: null,
                                      selectedImage: file,
                                      imgPrev: reader.result, // URL da imagem
                                    });
                                  };
                                  reader.readAsDataURL(file);
                                }
                              }
                            }}
                          />
                          <FontAwesomeIcon icon={faPen} color="#fff" />
                        </div>
                        <img src={this.state.imgPrev} />
                      </div>
                    </>
                  ) : (
                    <>
                      <div class="add" style={{ marginTop: 0 }}>
                        <input
                          type="file"
                          onChange={(e) => {
                            const file = e.target.files[0];
                            if (file) {
                              const fileType = file.type;
                              if (!fileType.startsWith("image/")) {
                                toast.warn(
                                  "Por favor, selecione uma imagem válida!"
                                );
                                e.target.value = null;
                              } else {
                                const reader = new FileReader();
                                reader.onloadend = () => {
                                  this.setState({
                                    slc: null,
                                    selectedImage: file,
                                    imgPrev: reader.result, // URL da imagem
                                  });
                                };
                                reader.readAsDataURL(file);
                              }
                            }
                          }}
                        />
                        <FontAwesomeIcon icon={faPlus} color="#282828" />
                      </div>
                    </>
                  )}
                </div>
              </>
            ) : (
              <>Vázio</>
            )}

            {this.state.Galery.length > 0 && (
              <>
                {this.state.Galery.map((item, index) => (
                  <>
                    <img
                      src={`${apiUrl}/img/${item.img}`}
                      onClick={(e) => {
                        this.setState({
                          imgPrev: null,
                          selectedImage: null,
                          slc: index,
                          imgSlc: item.img,
                        });
                      }}
                      style={{
                        width: 100,
                        height: 100,
                        cursor: "pointer",
                        border: `solid 1px ${
                          this.state.slc == index ? "red" : "black"
                        }`,
                      }}
                    />
                  </>
                ))}
              </>
            )}
          </div>

          <hr />
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <button
              className="btn btn-primary"
              onClick={(e) => {
                var t = this.state.openItem;

                if (this.state.selectedImage != null) {
                  this.handleChange(t.id, t.img, t.index);
                } else {
                  e.target.classList.add("load");

                  fetch(`${apiUrl}/sbt-apc/`, {
                    method: "POST",
                    credentials: "include",
                    headers: {
                      "Content-Type": "application/json",
                    },
                    body: JSON.stringify({
                      id: t.id,
                      type: t.img,
                      img: this.state.imgSlc,
                    }),
                  }).then((response) => {
                    e.target.classList.remove("load");
                    toast.success("Sucesso!");

                    this.setState((prevState) => {
                      const novoGrupo2 = [...prevState.apc];
                      novoGrupo2[t.index][t.img] = this.state.imgSlc;
                      return { apc: novoGrupo2 };
                    });

                    this.setState({
                      open: false,
                      openItem: null,
                      imgPrev: null,
                      slc: -1,
                      Galery: [],
                      selectedImage: null,
                    });
                  });
                }
              }}
            >
              <FontAwesomeIcon icon={faSave} /> Salvar
            </button>
          </div>
        </StaticDialog>

        <StaticDialog
          isOpen={this.state.edit != ""}
          title="editar imagem"
          onAfterClose={(result) => {
            this.setState({ edit: "" });
            // do something with dialog result
          }}
        >
          <ImageMarker
            imageSrc={`${apiUrl}/img/${this.state.edit.img}`}
            isOpen={this.state.edit != ""}
            onUpload={(blob) => {
              const formData = new FormData();
              formData.append("id", this.state.edit.id);
              formData.append("q", this.state.edit.q);
              formData.append("image", blob, "update.png");
              fetch(`${apiUrl}/alterar-imagem-apc`, {
                method: "POST",
                body: formData,
              }).then((response) => {
                toast.success("Imagem editada com sucesso!");

                response.json().then((data) => {
                  this.setState((prevState) => {
                    const novoGrupo2 = [...prevState.apc];

                    novoGrupo2[this.state.edit.index][this.state.edit.q] =
                      data.img;

                    return { apc: novoGrupo2 };
                  });
                  this.setState({ edit: "" });
                });
              });
            }}
            item={[]}
          />
        </StaticDialog>
      </>
    );

    //inactive

    return (
      <>
        <div class="card">
          <div class="card-header d-flex justify-content-between">
            <h3>AÇÕES PROPOSTAS COMO SUGESTÃO DE ADEQUAÇÃO</h3>
            <div class="custom-file">
              <button
                class="btn btn-primary btn-upl"
                onClick={() => {
                  fetch(
                    `${apiUrl}/add-apc/${
                      window.location.pathname.split("/")[3]
                    }`,
                    {
                      method: "POST",
                      credentials: "include",
                      headers: {
                        "Content-Type": "application/json",
                      },
                      body: JSON.stringify({
                        type: 0,
                        code: this.props.code,
                      }),
                    }
                  ).then((response) => {
                    if (response.status == 200) {
                      response.json().then((data) => {
                        toast.success("Item adicionado!");
                        this.setState((prevState) => ({
                          grupo: [
                            ...prevState.grupo,
                            { id: data.id, type: 0, text: null }, // Adiciona um novo item com imgs como array vazio
                          ],
                        }));
                      });
                    }
                  });
                }}
              >
                <FontAwesomeIcon icon={faPlus} /> Texto
              </button>
              <button
                class="btn btn-primary btn-upl"
                style={{ marginLeft: 10 }}
                onClick={() => {
                  fetch(
                    `${apiUrl}/add-apc/${
                      window.location.pathname.split("/")[3]
                    }`,
                    {
                      method: "POST",
                      credentials: "include",
                      headers: {
                        "Content-Type": "application/json",
                      },
                      body: JSON.stringify({
                        type: 1,
                        code: this.props.code,
                      }),
                    }
                  ).then((response) => {
                    if (response.status == 200) {
                      response.json().then((data) => {
                        toast.success("Item adicionado!");
                        this.setState((prevState) => ({
                          grupo: [
                            ...prevState.grupo,
                            { id: data.id, type: 1, imgs: [] }, // Adiciona um novo item com imgs como array vazio
                          ],
                        }));
                      });
                    }
                  });
                }}
              >
                <FontAwesomeIcon icon={faPlus} /> Imagens
              </button>
            </div>
          </div>
          <div class="card-body">
            {this.state.grupo.length > 0 ? (
              <>
                <table class="table ntbl table-bordered">
                  <thead>
                    <tr>
                      <th scope="col">Item</th>
                      <th scope="col">Opção</th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.grupo.map((item, index) => (
                      <>
                        <tr>
                          <td>
                            {item.type == 0 ? (
                              <>
                                <div
                                  style={{
                                    border: "solid 1px #ccc",
                                    borderRadius: 5,
                                    padding: 10,
                                    cursor: "pointer",
                                  }}
                                  onClick={() => {
                                    fetch(`${apiUrl}/text-list`, {
                                      method: "GET",
                                      credentials: "include",
                                      headers: {
                                        "Content-Type": "application/json",
                                      },
                                    }).then((response) => {
                                      if (response.status == 200) {
                                        response.json().then((data) => {
                                          this.setState({ textList: data });
                                        });
                                      }
                                    });

                                    this.setState({
                                      modal: true,
                                      ntext: item.text,
                                      id: item.id,
                                    });
                                  }}
                                >
                                  <span>
                                    {item.text == null ? "Digite:" : item.text}
                                  </span>
                                </div>

                                {/* <textarea placeholder='Digite:' value={item.text} onChange={(e)=>{
               this.setState((prevState) => {
                const updatedGroupi = [...prevState.grupo];
                
               
                updatedGroupi[index].text = e.target.value;
                
                return { grupo: updatedGroupi };
              });
            }} onBlur={()=>{
              
                fetch(`${apiUrl}/update-apc/`, {
                    method: 'POST',
                    credentials: 'include',
                    headers: {
                      'Content-Type': 'application/json',
                    },
                    body:JSON.stringify({
                    id:item.id,
                    text:item.text
                    })
                  }).then(response => {
if(response.status == 200){
    toast.success("Salvo!")
}
                  })





            }} className='form-control'/>
           */}
                              </>
                            ) : (
                              <>
                                <div class="area-8">
                                  {item.img1 != null ? (
                                    <>
                                      <div class="preview">
                                        <div
                                          class="edit"
                                          onClick={() => {
                                            this.LoadGalery();

                                            this.setState({
                                              open: true,
                                              openItem: {
                                                id: item.id,
                                                index,
                                                file: item.img1,
                                                img: "img1",
                                              },
                                            });
                                          }}
                                        >
                                          <FontAwesomeIcon
                                            icon={faPen}
                                            color="#fff"
                                          />
                                        </div>
                                        <img
                                          src={`${apiUrl}/img/${item.img1}`}
                                        />
                                      </div>
                                    </>
                                  ) : (
                                    <>
                                      <div
                                        class="add"
                                        onClick={() => {
                                          this.LoadGalery();

                                          this.setState({
                                            open: true,
                                            openItem: {
                                              id: item.id,
                                              index,
                                              file: item.img1,
                                              img: "img1",
                                            },
                                          });
                                        }}
                                      >
                                        <FontAwesomeIcon
                                          icon={faPlus}
                                          color="#282828"
                                        />{" "}
                                      </div>
                                    </>
                                  )}

                                  {item.img2 != null ? (
                                    <>
                                      <div class="preview">
                                        <div
                                          class="edit"
                                          onClick={() => {
                                            this.LoadGalery();

                                            this.setState({
                                              open: true,
                                              openItem: {
                                                id: item.id,
                                                index,
                                                file: item.img2,
                                                img: "img2",
                                              },
                                            });
                                          }}
                                        >
                                          <FontAwesomeIcon
                                            icon={faPen}
                                            color="#fff"
                                          />
                                        </div>
                                        <img
                                          src={`${apiUrl}/img/${item.img2}`}
                                        />
                                      </div>
                                    </>
                                  ) : (
                                    <>
                                      <div
                                        class="add"
                                        onClick={() => {
                                          this.LoadGalery();

                                          this.setState({
                                            open: true,
                                            openItem: {
                                              id: item.id,
                                              index,
                                              file: item.img2,
                                              img: "img2",
                                            },
                                          });
                                        }}
                                      >
                                        <FontAwesomeIcon
                                          icon={faPlus}
                                          color="#282828"
                                        />{" "}
                                      </div>
                                    </>
                                  )}

                                  {item.img3 != null ? (
                                    <>
                                      <div class="preview">
                                        <div
                                          class="edit"
                                          onClick={() => {
                                            this.LoadGalery();

                                            this.setState({
                                              open: true,
                                              openItem: {
                                                id: item.id,
                                                index,
                                                file: item.img3,
                                                img: "img3",
                                              },
                                            });
                                          }}
                                        >
                                          <FontAwesomeIcon
                                            icon={faPen}
                                            color="#fff"
                                          />
                                        </div>
                                        <img
                                          src={`${apiUrl}/img/${item.img3}`}
                                        />
                                      </div>
                                    </>
                                  ) : (
                                    <>
                                      <div
                                        class="add"
                                        onClick={() => {
                                          this.LoadGalery();

                                          this.setState({
                                            open: true,
                                            openItem: {
                                              id: item.id,
                                              index,
                                              file: item.img3,
                                              img: "img3",
                                            },
                                          });
                                        }}
                                      >
                                        <FontAwesomeIcon
                                          icon={faPlus}
                                          color="#282828"
                                        />{" "}
                                      </div>
                                    </>
                                  )}
                                </div>
                              </>
                            )}
                          </td>
                          <td>
                            <button
                              className="btn btn-danger"
                              onClick={(e) => {
                                this.setState({ delete: true, item });
                              }}
                            >
                              <FontAwesomeIcon icon={faTrash} />
                            </button>
                          </td>
                        </tr>
                      </>
                    ))}
                  </tbody>
                </table>
              </>
            ) : (
              <>Vázio!</>
            )}
          </div>
        </div>

        <StaticDialog
          isOpen={this.state.modal}
          title="TEXTO:"
          onAfterClose={(result) => {
            this.setState({ modal: false, id: null });
          }}
        >
          <div style={{ padding: 10 }}>
            <textarea
              class="form-control form-control-sm"
              onChange={(e) => {
                this.setState({ ntext: e.target.value });
              }}
              value={this.state.ntext}
              placeholder="Digite:"
            ></textarea>
            <input
              style={{
                width: "100%",
                height: 35,
                marginTop: 10,
              }}
              type="text"
              onChange={(e) => {
                this.setState({ psq: e.target.value });
              }}
              placeholder="Pesquisar:"
            />
            <div class="area-ptxt">
              {listaFiltrada.length > 0 ? (
                <>
                  <ul>
                    {listaFiltrada.map((item) => (
                      <>
                        <li
                          onClick={() => {
                            this.setState({ ntext: item.text });
                          }}
                        >
                          {item.text}
                        </li>
                      </>
                    ))}
                  </ul>
                </>
              ) : (
                <>Não há nada aqui!</>
              )}
            </div>
          </div>

          <hr />

          <div
            class="d-flex justify-content-end"
            style={{
              margin: 10,
            }}
          >
            <button
              type="button"
              class="btn btn-primary"
              onClick={() => {
                this.handleChange2();
              }}
              disabled={this.state.text != this.state.ntext ? false : true}
            >
              <FontAwesomeIcon icon={faSave} color="#fff" /> Salvar
            </button>
          </div>
        </StaticDialog>
        <StaticDialog
          isOpen={this.state.delete}
          title="Atenção"
          onAfterClose={(result) => {
            this.setState({ delete: false, item: {} });
            // do something with dialog result
          }}
        >
          <div style={{ margin: 10 }}>
            Uma vez deletado, não será possível recuperar
          </div>
          <hr />
          <button
            className="btn btn-danger"
            onClick={(e) => {
              e.target.classList.add("load");
              var id = this.state.item.id;

              fetch(`${apiUrl}/remove-apc/${id}`, {
                method: "DELETE",
              })
                .then((response) => {
                  if (response.status == 200) {
                    e.target.classList.remove("load");
                    toast.success("Sucesso!");
                    this.setState((prevState) => ({
                      grupo: prevState.grupo.filter((item) => item.id !== id),
                      delete: false,
                    }));
                  }
                })
                .catch(() => {
                  e.classList.remove("load");
                  toast.error("Falha na comunicação!");
                });
            }}
          >
            <FontAwesomeIcon icon={faTrash} color="#fff" /> Remover
          </button>
        </StaticDialog>

        <StaticDialog
          isOpen={this.state.open}
          title="Galeria"
          onAfterClose={(result) => {
            this.setState({
              open: false,
              openItem: null,
              imgPrev: null,
              slc: -1,
              Galery: [],
              selectedImage: null,
            });
            // do something with dialog result
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              padding: 10,
              flexWrap: "wrap",
              gap: 10,
              maxHeight: 400,
              overflowX: "auto",
            }}
          >
            {this.state.openItem != null ? (
              <>
                <div class="area-8" style={{ marginRight: -10 }}>
                  {this.state.imgPrev != null ? (
                    <>
                      <div class="preview" style={{ marginTop: 0 }}>
                        <div class="edit">
                          <input
                            type="file"
                            onChange={(e) => {
                              const file = e.target.files[0];
                              if (file) {
                                const fileType = file.type;
                                if (!fileType.startsWith("image/")) {
                                  toast.warn(
                                    "Por favor, selecione uma imagem válida!"
                                  );
                                  e.target.value = null;
                                } else {
                                  const reader = new FileReader();
                                  reader.onloadend = () => {
                                    this.setState({
                                      slc: null,
                                      selectedImage: file,
                                      imgPrev: reader.result, // URL da imagem
                                    });
                                  };
                                  reader.readAsDataURL(file);
                                }
                              }
                            }}
                          />
                          <FontAwesomeIcon icon={faPen} color="#fff" />
                        </div>
                        <img src={this.state.imgPrev} />
                      </div>
                    </>
                  ) : (
                    <>
                      <div class="add" style={{ marginTop: 0 }}>
                        <input
                          type="file"
                          onChange={(e) => {
                            const file = e.target.files[0];
                            if (file) {
                              const fileType = file.type;
                              if (!fileType.startsWith("image/")) {
                                toast.warn(
                                  "Por favor, selecione uma imagem válida!"
                                );
                                e.target.value = null;
                              } else {
                                const reader = new FileReader();
                                reader.onloadend = () => {
                                  this.setState({
                                    slc: null,
                                    selectedImage: file,
                                    imgPrev: reader.result, // URL da imagem
                                  });
                                };
                                reader.readAsDataURL(file);
                              }
                            }
                          }}
                        />
                        <FontAwesomeIcon icon={faPlus} color="#282828" />
                      </div>
                    </>
                  )}
                </div>
              </>
            ) : (
              <>Vázio</>
            )}

            {this.state.Galery.length > 0 && (
              <>
                {this.state.Galery.map((item, index) => (
                  <>
                    <img
                      src={`${apiUrl}/img/${item.img}`}
                      onClick={(e) => {
                        this.setState({
                          imgPrev: null,
                          selectedImage: null,
                          slc: index,
                          imgSlc: item.img,
                        });
                      }}
                      style={{
                        width: 100,
                        height: 100,
                        cursor: "pointer",
                        border: `solid 1px ${
                          this.state.slc == index ? "red" : "black"
                        }`,
                      }}
                    />
                  </>
                ))}
              </>
            )}
          </div>

          <hr />
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <button
              className="btn btn-primary"
              onClick={(e) => {
                var t = this.state.openItem;

                if (this.state.selectedImage != null) {
                  this.handleChange(t.id, t.img, t.index);
                } else {
                  e.target.classList.add("load");

                  fetch(`${apiUrl}/sbt-apc/`, {
                    method: "POST",
                    credentials: "include",
                    headers: {
                      "Content-Type": "application/json",
                    },
                    body: JSON.stringify({
                      id: t.id,
                      type: t.img,
                      img: this.state.imgSlc,
                    }),
                  }).then((response) => {
                    e.target.classList.remove("load");
                    toast.success("Sucesso!");
                    this.setState((prevState) => {
                      const novoGrupo2 = [...prevState.grupo];
                      novoGrupo2[t.index][t.img] = this.state.imgSlc;
                      return { grupo: novoGrupo2 };
                    });

                    this.setState({
                      open: false,
                      openItem: null,
                      imgPrev: null,
                      slc: -1,
                      Galery: [],
                      selectedImage: null,
                    });
                  });
                }
              }}
            >
              <FontAwesomeIcon icon={faSave} /> Salvar
            </button>
          </div>
        </StaticDialog>
      </>
    );
  }
}

export default Apc;
