import React, { Component } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Link } from "react-router-dom";
import { apiUrl } from "../../config";
import Modal from "../../Comps/Modal";
import Cookies from "js-cookie";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleLeft, faSave } from "@fortawesome/free-solid-svg-icons";
import InputMask from "react-input-mask";
class CadastroCli extends Component {
  constructor(props) {
    super(props);
    this.state = {
      estado: [
        "AC",
        "AL",
        "AP",
        "AM",
        "BA",
        "CE",
        "DF",
        "ES",
        "GO",
        "MA",
        "MT",
        "MS",
        "MG",
        "PA",
        "PB",
        "PR",
        "PE",
        "PI",
        "RJ",
        "RN",
        "RS",
        "RO",
        "RR",
        "SC",
        "SP",
        "SE",
        "TO",
      ],
      selectedImage: null,
      cadastro_empresa: {
        razao_social: "",
        cnpj: "",
        endereco: "",
        estado: "",
        cep: "",
      },
    };
  }

  componentDidMount() {
    document.title = "Cadastro de contratante";
  }

  handleImageChange = (event) => {
    const file = event.target.files[0];
    if (file && file.type.startsWith("image/")) {
      this.setState({
        selectedImage: file,
      });
    } else {
      alert("Por favor, selecione um arquivo de imagem válido.");
    }
  };

  handleChange = (e) => {
    const { name, value } = e.target;
    this.setState((prevState) => ({
      cadastro_empresa: {
        ...prevState.cadastro_empresa,
        [name]: value,
      },
    }));
  };

  render() {
    const { cadastro_empresa, selectedImage } = this.state;
    return (
      <>
        <header class="bg-white shadow">
          <div class="max-w-7xl mx-auto py-6 px-4 sm:px-6 lg:px-8">
            <button
              class="font-semibold text-xl text-gray-800 leading-tight"
              onClick={() => {
                this.props.navigate(-1);
              }}
            >
              <FontAwesomeIcon icon={faAngleLeft} /> Cadastro do contratante
            </button>
          </div>
        </header>
        <main>
          <div class="py-12">
            <div class="max-w-7xl mx-auto sm:px-6 lg:px-8">
              <div class="col-12">
                <div class="row">
                  <div class="py-12">
                    <div class="max-w-7xl mx-auto sm:px-6 lg:px-8">
                      <div class="col-12">
                        <div class="card">
                          <div class="card-header d-flex justify-content-between">
                            <h4>CADASTRO DO CONTRATANTE</h4>
                          </div>

                          <div class="card-body">
                            <table class="table table-bordered">
                              <thead>
                                <tr>
                                  <th scope="col">Título</th>
                                  <th scope="col">Input</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                                  <td>Imagem logo</td>
                                  <td>
                                    <div class="">
                                      <input
                                        class="form-control"
                                        type="file"
                                        onChange={this.handleImageChange}
                                        id="formFile"
                                      />
                                    </div>
                                  </td>
                                </tr>
                                <tr>
                                  <td>Razão social:</td>
                                  <td>
                                    <input
                                      type="text"
                                      className="form-control"
                                      placeholder="Digite a razão social"
                                      name="razao_social"
                                      value={cadastro_empresa.razao_social}
                                      onChange={this.handleChange}
                                    />
                                  </td>
                                </tr>

                                <tr>
                                  <td>CNPJ:</td>
                                  <td>
                                    <InputMask
                                      mask="99.999.999/9999-99"
                                      className="form-control"
                                      placeholder="Digite o CNPJ"
                                      name="cnpj"
                                      value={cadastro_empresa.cnpj}
                                      onChange={this.handleChange}
                                    />
                                  </td>
                                </tr>

                                <tr>
                                  <td>Endereço:</td>
                                  <td>
                                    <input
                                      type="text"
                                      className="form-control"
                                      placeholder="Digite o endereço"
                                      name="endereco"
                                      value={cadastro_empresa.endereco}
                                      onChange={this.handleChange}
                                    />
                                  </td>
                                </tr>
                                <tr>
                                  <td>Estado:</td>
                                  <td>
                                    <select
                                      className="form-control"
                                      name="estado"
                                      value={cadastro_empresa.estado}
                                      onChange={this.handleChange}
                                    >
                                      <option value="">
                                        Selecione o estado
                                      </option>
                                      {this.state.estado.map((estado) => (
                                        <option key={estado} value={estado}>
                                          {estado}
                                        </option>
                                      ))}
                                    </select>
                                  </td>
                                </tr>
                                <tr>
                                  <td>Cep:</td>
                                  <td>
                                    <InputMask
                                      mask="99.999-999"
                                      className="form-control"
                                      placeholder="Digite o cep"
                                      name="cep"
                                      value={cadastro_empresa.cep}
                                      onChange={this.handleChange}
                                    />
                                  </td>
                                </tr>
                                <tr>
                                  <td>Telefone:</td>
                                  <td>
                                    <input
                                      type="text"
                                      className="form-control"
                                      placeholder="Digite o telefoneo"
                                      name="telefone"
                                      value={cadastro_empresa.telefone}
                                      onChange={this.handleChange}
                                    />
                                  </td>
                                </tr>

                                <tr>
                                  <td>E-mail:</td>
                                  <td>
                                    <input
                                      type="text"
                                      className="form-control"
                                      placeholder="Digite o email"
                                      name="email"
                                      value={cadastro_empresa.email}
                                      onChange={this.handleChange}
                                    />
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                            <div class="card-fotter">
                              <button
                                type="button"
                                class="btn btn-primary float-right ml-2"
                                onClick={() => {
                                  if (selectedImage == undefined) {
                                    toast.error("Selecione o LOGO!");
                                    return false;
                                  }
                                  const id = toast.loading("Enviando...");

                                  const formData = new FormData();
                                  formData.append("image", selectedImage);
                                  Object.keys(cadastro_empresa).forEach(
                                    (key) => {
                                      formData.append(
                                        key,
                                        cadastro_empresa[key]
                                      );
                                    }
                                  );

                                  fetch(
                                    `${apiUrl}/cadastro-empresa/adicionar`,
                                    {
                                      method: "POST",
                                      body: formData,
                                    }
                                  )
                                    .then((response) => {
                                      if (response.status == 200) {
                                        response.json().then((data) => {
                                          toast.update(id, {
                                            render:
                                              "Cadastro realizado com sucesso!",
                                            type: "success",
                                            isLoading: false,
                                            autoClose: 3000,
                                          });

                                          this.props.navigate(
                                            `/cadastro-solicitante/preview/${data.token}`
                                          );
                                        });
                                      }
                                      if (response.status == 422) {
                                        response.json().then((data) => {
                                          toast.update(id, {
                                            render: data.error,
                                            type: "error",
                                            isLoading: false,
                                            autoClose: 3000,
                                          });
                                        });
                                      }
                                    })
                                    .catch((err) => {
                                      toast.update(id, {
                                        render: "erro",
                                        type: "error",
                                        isLoading: false,
                                        autoClose: 3000,
                                      });
                                    });
                                }}
                              >
                                {" "}
                                <FontAwesomeIcon
                                  color="#fff"
                                  icon={faSave}
                                />{" "}
                                Salvar
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
        <ToastContainer />
        <div id="modal-root"></div>
      </>
    );
  }
}

export default CadastroCli;
